// common color variables
$color-red: red;
$color-green: #27c346;
$color-light-gray: #cccccc;
$color-link: #e68045;

.text-color-red {
    color: $color-red;
}

//clone url styles start here
.url-cls {
    border: 1px solid $color-light-gray;
    padding: 10px;
    margin-left: 10px;
    margin-top: 3px;
    border-radius: 4px;
    font-size: 18px;
    color: $color-link;
    height: 44px;
}

.url-cls-2 {
    border: 1px solid $color-light-gray;
    padding: 8px;
    margin-left: 10px;
    margin-top: 3px;
    border-radius: 4px;
    font-size: 18px;
    color: #e68045;
    height: 40px;
}

// canvas responsive scrolling styles

.canvas-scrollbar {
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: initial;
        visibility: visible;
        border-radius: 10px;
        height: 10px;
        background-color: $color-light-gray;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: 0 0;
        background-color: rgba(0, 0, 0, 0.2);
        border: 2px solid transparent;
        border-radius: 10px;
        background-clip: padding-box;
    }
}

.canvas-wrapper {
    position: relative;
    width: auto;
    height: 650px;
}

/* sub header css for mobile screen only - header, date & time, clockout*/
@media only screen and (max-width: 425px) {
    .content-header {
        margin-top: 9px !important;

        .right-div p {
            font-size: 11px !important;
        }

        .content-header-name {
            font-size: 16px;
            margin-top: 0px;
            padding-right: 0px;
            min-width: fit-content;
        }

        .filter_header {
            margin-top: 10px;
            padding-left: 5px !important;
            padding-right: 5px !important;
        }

        .right-div button {
            padding: 4px 4px;
            font-size: 12px;
            min-width: 76px;
        }
    }
    .reports-tab .mat-mdc-tab-body.mat-mdc-tab-body-active,
    .reports-det .mat-mdc-tab-body-wrapper {
        overflow: hidden !important;
    }
}

.primary-text {
    color: $sidebar-selected-item;
}

.button-active,
.active-tip {
    border: 1px solid $sidebar-selected-item !important;

    .pay-cart-btn-txt {
        color: $sidebar-selected-item;
    }

    i {
        color: $sidebar-selected-item;
        font-size: 20px;
    }
}

.urldiv .fas {
    font-size: 18px;
    margin-left: 3px;
    color: #e68045;
}

.urldiv {
    padding: 2px 11px;
    max-width: 500px;
}

.urltext {
    padding-top: 15px;
    color: #0a0a0a;
}

// dashboard
#dashboardData .box {
    background-color: $sidebar-selected-item !important;
}

//clone url styles end here

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-label-content {
    padding-left: 7px;
    padding-right: 8px;
    font-weight: 300;
}

.mdc-tab--active,
.mat-mdc-tab-group.mat-primary .form-action-btn .mat-mdc-tab.cdk-focused:focus:not(.mat-tab-disabled),
.mdc-tab--active.mat-mdc-raised-button {
    font-size: 14px;
    background-color: #ffffff;
    color: $sidebar-selected-item;
    border-radius: 30px;
    border: none;
}

.reportsFilterBtn {
    background-color: rgba($sidebar-selected-item, 0.2) !important;
    color: $sidebar-selected-item !important;
    font-size: 14px;
    border-radius: 30px;
    border: none;
}

/* common buttons css */
.mat-mdc-tab-header-noborder > .mat-mdc-tab-header .mdc-tab--active {
    border-radius: 0px;
}

.form-button-40 {
    border-radius: 30px !important;
}

.form-height-40 {
    height: 40px !important;
}

.btn-div button {
    color: #fff;
    padding: 2px 25px;
    border-radius: 30px;
    border: none;
    margin: 10px 5px;
    font-weight: 500;
    font-size: 15px;
}

.form-buttons {
    display: inline-block;
    float: right;

    button {
        color: #fff;
        padding: 0px 15px;
        border-radius: 30px;
        border: none;
        margin: 10px 5px;
        font-weight: 500;
        font-size: 15px;
    }

    .ash {
        background-color: #e0e0e0;
        color: #6e6e6e;
    }

    .back {
        background-color: white;
        color: #898989;
        border: 1px solid;
        padding-right: 10px;
    }

    .green {
        background-color: $color-green;
    }
}

.form-buttons .cancel,
.btn-div .cancel-btn {
    background-color: #e0e0e0;
    color: black;
}

.btn-div .save-btn {
    background-color: $color-green !important;
    margin-left: 15px;
    color: white !important;
}

/* common buttons css */

.pay-now .enable-btn.mat-mdc-button.mat-primary:hover {
    background-color: $sidebar-selected-item !important;
}

.tooltiplabel {
    margin-left: 5px;
    color: #000000;
}

table {
    .actionIcon .btn {
        padding: 4px;
    }

    i {
        font-size: 16px;
    }

    .mat-column-Action {
        padding-right: 10px !important;
    }

    thead tr.mat-mdc-header-row {
        height: 42px;

        th {
            background-color: #f9f9f9;
            border-bottom: 2px solid #939393;
        }
    }

    .mat-mdc-row:nth-child(even) {
        background-color: #f9f9f9;
    }

    .mat-mdc-row:nth-child(odd) {
        background-color: #fff;
    }
}

/** common icons css **/
.delete-icon {
    color: $color-red !important;
}

.edit-icon {
    color: #337ab7;
    font-size: 16px;
}

.list-icon {
    color: #4b0082;
}

.view-icon {
    color: #4b0082;
}

i.far.fa-edit {
    color: #337ab7;
}

.delete-icon,
.error-message {
    color: #f05050;
}

.ban-icon {
    color: #7d7b7b;
}

.check-icon {
    color: green;
    font-size: 1.6em;
}

.colorPDF {
    color: #00963c;
}

/** common icons css **/
.mat-mdc-table {
    width: 100%;
}

td.mat-mdc-cell {
    font-weight: 500 !important;
}

.table-fields {
    display: inline-block;
    width: 100%;
    padding: 5px 15px;
    min-height: 42px;
}

.table-fields mat-form-field {
    display: inline-block;
    width: auto;
}

.search {
    float: right;
}

.mat-mdc-tab .mdc-tab__content {
    font-weight: bold;
}

/** menu common css **/
.user_indicate_msg .invoice_count {
    color: $sidebar-selected-item;
}

.mat-mdc-dialog-container,
.mat-mdc-card {
    background: #ffffff;
    color: #000000;
}

#main-content {
    background: #f2f3f5;
    min-height: 500px;
    border-radius: 5px;
    color: #000000;
}

.headerDiv {
    color: $sidebar-selected-item;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 700;
}

.mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab {
    padding: 0 15px 0 0;
    font-weight: 700;
    font-family: Roboto, 'Helvetica Neue', sans-serif;

    &.mdc-tab--active .mdc-tab__text-label {
        background: #ffffff00;
    }

    .mdc-tab__text-label {
        letter-spacing: 0.1px;
    }
}

.page-content .mdc-tab--active > a {
    background-color: $sidebar-selected-item;
    color: #fff;
}

#snav #matnavList .mat-mdc-list-item a {
    color: #000;
    text-decoration: none !important;
}

#snav #matnavList .mat-mdc-list-item a:hover {
    color: $sidebar-selected-item;
    text-decoration: none !important;
}

#snav #matnavList .mat-mdc-list-item.active a {
    background: #ffffff;
    color: $sidebar-selected-item;
}

#snav #matnavList .mat-mdc-list-item.active > .mdc-list-item__content a mat-icon {
    color: inherit;
}

#snav #matnavList .mat-mdc-list-item.active > .mdc-list-item__content a {
    background: transparent;
    color: $sidebar-selected-item;
    text-decoration: none !important;
}

#snav #matnavList .mat-mdc-list-item.active > .mdc-list-item__content a mat-icon {
    color: inherit;
}

.mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab {
    padding: 0px 15px;
}

.mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab:first-child {
    padding-left: 0px;
}

.mat-mdc-tab-header-noborder > mat-tab-header.mat-mdc-tab-header:first-of-type .mat-mdc-tab:first-child {
    padding-left: 15px;
}

.mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab.cdk-focused.cdk-mouse-focused,
.mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab:hover,
.mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab:focus,
.mat-mdc-tab-header .mat-ripple-element {
    background: transparent;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
mat-tab-header.mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/** menu common css **/

/** table common css **/
.fa.fa-file-pdf-o,
.fa.fa-file-excel-o,
.fas .fa-print {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 5px;
}

.fa.fa-chain {
    color: #969494;
}

.chainMapped {
    color: #27c24c !important;
}

td.mat-mdc-cell:first-of-type,
td.mat-mdc-footer-cell:first-of-type,
th.mat-mdc-header-cell:first-of-type {
    padding-left: 10px;
}

.btn-action.plus_icon {
    margin-left: 2px;
    vertical-align: super;
    padding: 0 5px;
    height: 24px;
}

tr td.mat-column-participants,
tr td.mat-column-Participants {
    padding-left: 15px;
}

td:not(:first-of-type),
th:not(:first-of-type) {
    padding: 2px;
}

.actionIcon .btn:focus,
.actionIcon .btn:hover {
    /*border: 0px;*/
    outline: none;
    box-shadow: none;
}

.all-caps {
    color: #333333;
    font-weight: 600;
}

.detailsPage .links a {
    font-size: 13px;
    text-transform: capitalize;
}

.detailsPage .phone_number {
    font-size: 13px;
}

.everyone_participants {
    margin-left: -10px;
    vertical-align: top;
}

.everyone_participants i {
    font-size: 10px;
    vertical-align: super;
    margin-left: 2px;
    border: 1px solid gray;
    border-radius: 50%;
    padding: 1px 4px;
    top: 5px;
    position: relative;
    background: #ddd;
    right: -4px;
}

#dashboardData .everyone_participants i,
.kanbanInfo-popup-card .everyone_participants i {
    vertical-align: sub;
}

.detailsPage .organization-details .everyone_participants {
    margin-left: 0px;
}

.content-header button.mat-mdc-button {
    padding: 0px;
}

.particpants_options {
    display: inline-block;
    margin-right: 30px;
    vertical-align: top;
}

.custom_dropdown {
    width: 40%;
    display: inline-block;
}

.list_tbl {
    min-height: 380px;
}

.ratingsTab button .mat-icon {
    color: $sidebar-selected-item;
}

#loader-task .mat-column-task_name a.btn,
.mat-column-testCase_Title span.btn,
.mat-column-Bug-Title a,
.task_timesheet .mat-column-username span,
.vendor_list .mat-column-Name a,
.client_list .mat-column-Name a,
#EmployeeCom .mat-column-fullname a,
.mat-column-Bug-Title span,
.opp_div.list_tbl .opp_name a,
.opp_div.list_tbl .client_lead_name a {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    word-break: break-word;
    padding-right: 10px;
    clear: both;
    text-align: left;
}

#invCom .mat-column-Invoice a,
#invCom .mat-column-Client-Name a,
.project-name-columnStatus a,
#estimate-cont-dev .mat-column-Client-Lead-Name a,
#inv-cont-dev .mat-column-Purchase-Order a,
.purchase_order .mat-column-Vendor-Name a,
#LeadsCom .mat-column-lead_name a,
.opp_div.list_tbl .opp_name a,
.Opppin-name-column a,
.ticket_list .mat-column-clientId a,
#loader-pro .mat-column-client a,
#dashboardData .mat-column-Leads a,
.Client-name-columnStatus a,
.mat-column-call_summary a,
.mat-column-meeting_subject a,
.mat-column-Name a,
.Tickets-column-Status a,
.Invoiceclient-name-columnStatus a,
.Opppin-clientLead-column a,
#dashboardData.dispBlock .mat-column-Project-Name a,
.displayCard .noPadding .display_prop td.mat-column-projectname a,
.displayCard .noPadding .display_prop td.mat-column-clientname a,
.displayCard .noPadding .display_prop td.mat-column-taskname a,
.displayCard .noPadding .display_prop td.mat-column-VendorName a,
.displayCard .noPadding td.mat-mdc-cell.cdk-column-ClientName.mat-column-ClientName a,
.displayCard .noPadding .mat-column-clientName a,
.mat-column-Legal-Company-Name a,
.mat-column-Project-Name a,
#loader-oppo .opp_div.list_tbl .client_lead_name a,
.rmaStatus .mat-column-clientName a,
.Leads-name-columnStatus a,
#dashboardData .CallsDetail .tableDiv .mat-column-Client-Leads a,
#dashboardData .leadsMeetingsDetail .tableDiv .mat-column-Client-Leads a,
#dashboardData.display_prop.allPinnedDetail.mat-column-Client a,
.rma-client-namecolumn a,
.mat-column-Client-Name a,
.mat-column-Client-Lead-Name a,
.mat-column-projectName a,
.mat-column-clientName a,
.attReportDetails .table-title,
.rmaDetails .rma-desc,
.rmaDetails .clientNames {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    word-break: break-word;
    padding-right: 10px;
    clear: both;
    text-align: left;
}

#loader-task .mat-column-task_name a.btn:hover,
.mat-column-testCase_Title span.btn:hover,
.mat-column-Bug-Title a:hover,
.task_timesheet .mat-column-username span:hover,
.Opppin-name-column a:hover,
.mat-column-projectName a:hover #invCom .mat-column-Invoice a:hover,
#invCom .mat-column-Client-Name a:hover,
.project-name-columnStatus a:hover,
#estimate-cont-dev .mat-column-Client-Lead-Name a:hover,
#inv-cont-dev .mat-column-Purchase-Order a:hover,
.mat-column-Name a:hover,
.mat-column-Client-Name a:hover,
#dashboardData #loader-pro .mat-column-projectName a:hover,
.purchase_order .mat-column-Vendor-Name a:hover,
#LeadsCom .mat-column-lead_name a:hover,
.client_list .mat-column-Name a:hover,
.vendor_list .mat-column-Name a:hover,
.opp_div.list_tbl .opp_name a:hover,
.opp_div.list_tbl .client_lead_name a:hover,
.ticket_list .mat-column-clientId a:hover,
#loader-pro .mat-column-client a:hover,
.Tickets-column-Status a:hover,
#EmployeeCom .mat-column-fullname a:hover,
.mat-column-Bug-Title span:hover,
#dashboardData .mat-column-Leads a:hover,
.Client-name-columnStatus a:hover,
.mat-column-call_summary a:hover,
.mat-column-task_name a:hover,
.mat-column-testCase_Title span.btn:hover,
.mat-column-Client-Name a:hover .mat-column-Invoice a:hover,
.Invoiceclient-name-columnStatus a:hover,
.mat-column-projectName a:hover,
.mat-column-Client-Lead-Name a:hover,
.opp_div.list_tbl .opp_name a:hover,
.mat-column-meeting_subject a:hover,
.Opppin-clientLead-column a:hover,
#dashboardData .dispBlock .mat-column-Project-Name a:hover,
.displayCard .noPadding .display_prop .mat-column-projectname a:hover,
.displayCard .noPadding .display_prop td.mat-column-ClientName a:hover,
.displayCard .noPadding .display_prop .mat-column-VendorName a:hover,
.displayCard .noPadding .display_prop .mat-column-taskname a:hover,
.displayCard .noPadding td.mat-mdc-cell.cdk-column-ClientName.mat-column-ClientName a:hover,
#dashboardData .allPinnedDetail .display_prop .mat-column-Name a:hover,
.mat-column-Legal-Company-Name a:hover,
.mat-column-Project-Name a:hover,
#loader-oppo .opp_div.list_tbl .client_lead_name a:hover,
.Leads-name-columnStatus a:hover,
#dashboardData .CallsDetail .tableDiv .mat-column-Client-Leads a:hover,
#dashboardData .leadsMeetingsDetail .tableDiv .mat-column-Client-Leads a:hover,
#dashboardData.allPinnedDetail.display_prop .mat-column-Client a:hover,
.mat-column-Client-Lead-Name a:hover,
.displayCard .noPadding .mat-column-clientName a:hover,
.displayCard .noPadding .display_prop td.mat-column-clientname a:hover,
.rma-client-namecolumn a:hover,
.mat-column-projectName a:hover,
.mat-column-clientName a:hover,
.attReportDetails .table-title:hover,
.rmaDetails .rma-desc:hover,
.rmaDetails .clientNames:hover {
    text-align: left;
    white-space: normal;
    text-overflow: unset;
    padding-right: 10px;
    clear: both;
}

/* for empty tables */
.mat-mdc-table.table_empty td,
.mat-mdc-table.t#snav #matnavList .mat-mdc-list-item.active > .mdc-list-item__content a {
    background: #ffffff;
    color: $sidebar-selected-item;
}

#snav #matnavList .mat-mdc-list-item.active > .mdc-list-item__content a mat-icon {
    color: inherit;
}

.table_empty th,
.mat-mdc-table.table_empty td a {
    max-width: unset !important;
    width: unset !important;
}

/** table common css **/

.comment-content p,
.note-data p {
    word-break: break-word;
    padding-left: 10px;
}

/** time bar css **/
.tickets-timebar {
    display: table;
    width: 100%;
    margin-top: 10px;
}

/** map design issue **/
.content_name {
    padding-bottom: 12px !important;
    padding-right: 20px !important;
}

/** time bar css **/

span.btn.btn-success:hover,
span.btn.btn-success:active {
    background-color: #5cb85c;
    border: 1px solid #4cae4c;
}

.detailsPage .personal-details li,
.detailsPage .participant-details li,
.detailsPage .other-details li {
    clear: both;
    margin-bottom: 5px;
}

.clientContactDetail td.itemnamecell.mat-mdc-cell.cdk-column-item_name.mat-column-item_name {
    width: 15%;
    padding: 5px;
}

.clientContactDetail .mat-column-tax_name,
.clientContactDetail .mat-column-item_desc {
    padding: 5px;
    width: 10%;
}

.discountsuffix {
    vertical-align: super;
    padding-right: 5px;
}

.message-text {
    overflow: scroll;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
        display: initial;
        visibility: visible;
        border-radius: 10px;
        height: 8px;
        background-color: $color-light-gray;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: 0 0;
        background-color: rgba(0, 0, 0, 0.2);
        border: 2px solid transparent;
        border-radius: 10px;
        background-clip: padding-box;
    }
}

button.mat-mdc-menu-item,
a.mat-menu-item {
    padding: 0px 5px;
    height: 30px;
    color: #000;
    min-height: unset;
    font-size: 12px !important;
    line-height: 30px !important;

    .mat-mdc-menu-item-text {
        font-size: 12px !important;
        line-height: 30px !important;
    }
    &.txt-truncate {
        .mat-mdc-menu-item-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

button.mat-mdc-tab {
    border-radius: 50px;
    color: #fff;

    &.mat-primary {
        color: #fff;

        &:disabled {
            color: rgba(255, 255, 255, 0.26);
        }
    }
}

.mat-mdc-tab {
    height: 36px;
    min-width: 30px !important;
    width: auto;
    opacity: unset;
    color: #000;
}

.mat-mdc-tab-header {
    height: 53px;
}

.info-box .far,
.info-box .fa,
.info-box .fas {
    color: $sidebar-selected-item;
}

.mat-mdc-paginator-container {
    justify-content: space-between !important;
}

.mat-mdc-paginator-page-size-select {
    margin: 0px 5px !important;
}

.topbar.telative app-header {
    margin: 0 auto;
    width: 100%;
}

.search input {
    border: 1px solid lightgray !important;
    padding: 8px 7px 7px 8px !important;
    width: 200px !important;
    vertical-align: super;
}

.custom-item-btn.visibilityButtonIcon {
    vertical-align: super;
    margin-right: 0px !important;
    bottom: 0px;
    display: inline-flex;
}

.page-content .mat-elevation-z8 {
    box-shadow: none;
}

.mat-mdc-dialog-content p {
    font-size: 14px;
    font-weight: 400;
}

.content-header {
    height: 55px;

    @media screen and (max-width: 768px) {
        height: auto;
    }

    background: white;
    box-shadow: none;
}

.content-header .mat-icon {
    margin-top: 0;
    width: 15px;
}

/* Start filter */

.content-header {
    position: relative;
}

.content-header .content-header-name {
    display: inline-block;
}

.content-header .btn-filter {
    float: right;
}

.content-header .btn-filter mat-cion {
    line-height: 1.25;
    color: #666;
}

.content-header .filter-overlay {
    left: auto;
    right: 1em;
    min-width: 250px;
    padding: 10px;
}

.content-header .filter-dropdown {
    display: inline-block;
    float: right;
}

/* End filter */

/* Menu Builder Page */

.menu-wrap .mat-step {
    margin-bottom: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.cat-group .mat-radio-label-content {
    font-weight: 600 !important;
    text-transform: capitalize;
}

.cat-group mat-radio-button {
    border: 1px solid $sidebar-selected-item;
    padding: 4px 6px;
    border-radius: 29px;
    margin-right: 8px;
    margin-bottom: 8px;
    color: $sidebar-selected-item;
    transition: 0.4s all;
}

.cat-group mat-radio-button.mat-radio-checked,
.cat-group mat-radio-button:hover {
    background-color: $sidebar-selected-item;
    color: #fff;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.cat-group .mat-radio-label {
    margin-bottom: 0;
}

.menu-wrap mat-step-header {
    padding: 16px 24px;
    background-color: #eff0f2;
    pointer-events: none;
}

.menu-wrap .mat-vertical-content {
    padding: 24px 24px 24px 24px;
}

.menu-wrap .mat-stepper-vertical-line::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left-width: 1px;
    border-left-style: dashed;
    border-left-color: rgba(0, 0, 0, 0.2);
}

.menu-wrap .mat-step-header .mat-step-icon.mat-step-icon-selected {
    background-color: $sidebar-selected-item;
    color: rgb(255, 255, 255);
}

.menu-wrap .mat-step-header .mat-step-icon-state-edit,
.menu-wrap .mat-step-header .mat-step-icon {
    background-color: #c7cdda;
    color: rgba(0, 0, 0, 1);
}

.menu-wrap .mat-step-header .mat-step-label.mat-step-label-active {
    padding-top: 2px;
}

.menu-wrap .mat-step-header .mat-step-label-selected {
    color: $sidebar-selected-item !important;
}

.menu-wrap .item-box {
    background-color: $sidebar-selected-item;
}

.item-box mat-checkbox .mat-mdc-checkbox-inner-container,
.menu-wrap .promocode-li .mdc-checkbox,
.menu-wrap .modifier-li .mdc-checkbox,
.cat-group .mdc-radio__background {
    display: none;
}

.item-box mat-checkbox .mat-mdc-checkbox-label {
    line-height: 1;
}

.item-box mat-checkbox .mat-mdc-checkbox-layout {
    white-space: normal;
    margin-bottom: 0;
}

.menu-wrap .promocode-li .mat-mdc-checkbox label {
    line-height: 1;
    text-transform: capitalize;
}

.menu-wrap .select2-container.select2-container--open {
    position: relative !important;
    padding-bottom: 0 !important;
    bottom: 0 !important;
}

.menu-wrap .select2-container .select2-dropdown {
    position: relative;
    box-shadow: none !important;
}

.menu-wrap .select2-container--default .select2-results > .select2-results__options {
    max-height: 110px !important;
}

.menu-builder-filter .select2-container--default .select2-results > .select2-results__options {
    max-height: 85px !important;
}

.mat-mdc-unelevated-button.custom-theme-flat-button {
    background-color: $sidebar-selected-item;
    color: #fff;
    border-radius: 30px;
}

.mat-mdc-unelevated-button.custom-theme-flat-button:hover {
    box-shadow:
        0 40px 12px rgba(70, 156, 74, 0.25) inset,
        0 -40px 12px rgba(70, 156, 74, 0.25) inset;
}

.mat-mdc-outlined-button.custom-theme-stroked-button {
    border-radius: 30px;
    border-color: #99a0a6;
    color: #82898f;
}

.reports-tab .mat-mdc-tab-body-content {
    padding: 15px 10px;
}

/*** to display dropdown at top of daily reports  **/
.reports-tab .mat-mdc-tab-body.mat-mdc-tab-body-active,
.reports-det .mat-mdc-tab-body-wrapper {
    overflow: visible;
}

.category-box.active {
    border: 1px solid $sidebar-selected-item;
}

.category-box.active .category-name {
    background: $sidebar-selected-item;
    color: #fff;
}

.promocode-btn {
    float: right;
    border: 1px solid $sidebar-selected-item;
    background: #fff;
    margin: 0 10px;
    border-radius: 15px;
    background: $sidebar-selected-item;
    color: #fff;
    padding: 5px 15px;
}

.promocode-btn:hover {
    background: $sidebar-selected-item;
}

.form-inner .content-box .search {
    margin-top: 0;
}

.search button.mat-mdc-outlined-button {
    vertical-align: top;
}

.filter-btns-rounded button.active,
.custom-period-diolog .show-btn {
    background-color: $sidebar-selected-item !important;
    color: #fff;
}

.custom-file-upload .img-upload > .file-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    color: $sidebar-selected-item;
    margin-bottom: 0;
    cursor: pointer;
}

.toggle-button.active {
    background-color: $sidebar-selected-item;
    border-color: $sidebar-selected-item;
    color: #fff;
}

.toggle-button.active {
    background-color: $sidebar-selected-item;
    border-color: $sidebar-selected-item;
    color: #fff;
}

// End Menu Builder

table.mat-mdc-table {
    border-top: 1px solid #e0e0e0;
    border-bottom: 2px solid #939393;
}

.content-header-name {
    color: $sidebar-selected-item;
    font-size: 25px;
    display: inline-block;
}

#snav .mat-mdc-list-item a span {
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0;
}

#snav .mat-mdc-list-item a .mat-icon:before {
    vertical-align: sub;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
    margin: 0px auto;
    width: 60%;
}

#snav #matnavList .mat-mdc-list-item > .mdc-list-item__content a mat-icon:last-child {
    font-size: 18px;
}

#snav #matnavList .mat-mdc-list-item > .mdc-list-item__content a mat-icon {
    min-width: 20px;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 14px;
}

.errorClass {
    border: 2px solid $color-red;
    border-radius: 5px;
}

.noError {
    color: #000 !important;
}

.noError,
select2.mySelect2.ng-valid {
    border: 1px solid gainsboro;
    color: #000 !important;
    border-radius: 5px;
}

.mySelect2 {
    height: 44px !important;
    border-radius: 5px;
}

.selectDateRange {
    height: 43px !important;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #ddd;
    padding-left: 10px;
}

input.selectDateRange.ng-invalid[aria-invalid='true'] {
    border: 2px solid $color-red;
    border-radius: 5px;

    span.select2-selection__placeholder {
        color: $color-red !important;
    }
}

.mySelect2-multiple {
    height: auto !important;
    min-height: 40px !important;
}

.errorClass .mySelect2-multiple {
    height: auto !important;
}

span.select2-selection__rendered.ng-star-inserted {
    line-height: 16px !important;
}

span.select2-selection__rendered.ng-star-inserted {
    padding-left: 5px !important;
    top: 10px !important;
}

.material .select2-container .selection {
    padding: 8px !important;
}

span.select2-selection__placeholder.select2-selection__placeholder__option {
    margin: 0px !important;
    background: white;
    width: auto !important;
    left: 5px !important;
    padding: 2px 7px;
}

span.select2-selection__placeholder {
    top: 10px !important;
    left: 10px !important;
    color: rgb(61, 61, 61) !important;
    font-size: 12px !important;
    font-family: 'Source Sans Pro', sans-serif;
}

.errorClass span.select2-selection__placeholder {
    color: $color-red !important;
}

.mySelect2-multiple span.select2-selection__placeholder {
    top: 10px !important;
}

.select2-results__options {
    color: #000;
    overflow-y: scroll !important;
    max-height: 200px !important;
    height: 200px;
}

.surtemp_formBox,
.survey-box-2 {
    .select2-results__options {
        height: auto;
    }
}

/*crossbrowser scrollbar common css */
#opp-cont-dev,
#ven-cont-div,
#bank-cash-div,
#inv-cont-dev,
.writeCheck,
#dept-cont-dev,
.leaveManagementTab,
#estimate-cont-dev,
.leadsContactDetail,
.leadsMeetingsDetail,
.leadsOppoDetail,
#lead-cont-dev,
.audit-header,
#bug-cont-dev,
#tickets-cont-dev,
#taskDetail,
#task-cont-dev,
#roles-cont-dev,
#trash-cont-dev,
.Agreement,
.vendorContactDetail,
.clientContactDetail,
.vendorRefund,
.vendorTransaction,
.content {
    .mat-mdc-tab-body-content {
        overflow: hidden;
    }
}

/*End of crossbrowser scrollbar common css */

/*horizontal scroll  common css*/

.table_outer {
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    min-height: 350px;

    td {
        white-space: pre-line;
    }

    th.mat-mdc-header-cell {
        white-space: pre;
    }

    .mat-mdc-paginator {
        .mat-mdc-paginator-page-size {
            position: absolute;
        }

        .mat-mdc-paginator-range-actions {
            position: absolute;
            right: 5px;
        }
    }
}

.table_outer::-webkit-scrollbar {
    width: 100%;
    height: 10px;
    overflow: scroll;
}

.table_outer::-webkit-scrollbar-thumb,
.scrollable-ng-select ::-webkit-scrollbar-thumb {
    background: 0 0;
    background-color: rgba(0, 0, 0, 0.18);
    border: 2px solid transparent;
    border-radius: 20px;
    background-clip: padding-box;
}

.table_outer::-webkit-scrollbar,
.scrollable-ng-select ::-webkit-scrollbar {
    display: block !important;
}

.table_outer::-webkit-scrollbar :hover,
.scrollable-ng-select ::-webkit-scrollbar :hover {
    background-color: rgba(0, 0, 0, 0.3);
}

/*End of horizontal scroll  common css*/

.add_sub_dept .sub_dept_role .ng-dropdown-panel-items,
#dept-cont-dev .ng-dropdown-panel-items {
    max-height: 150px !important;
}

#opp-cont-dev .form-buttons,
#task-cont-dev .form-buttons {
    margin-bottom: 20px;
}

#tickets-cont-dev .select2-container.select2-container-dropdown,
#comments .select2-container.select2-container-dropdown,
#projects-cont-dev .select2-container.select2-container-dropdown,
#EmployeeCom .select2-container.select2-container-dropdown,
.milestoneDetail .select2-container.select2-container-dropdown,
.options_dropdown .select2-container.select2-container-dropdown {
    bottom: 35px !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    top: 5px !important;
}

.select2.select2-container.select2-container--default.select2-container--open.select2-container--below.select2-container--focus {
    height: auto !important;
}

.select2-container--open .select2-dropdown--below {
    min-width: 160px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #3d3d3d;
}

.lead_list .select2-container--open .select2-dropdown--below,
#opp-cont-dev .select2-container--open .select2-dropdown--below {
    right: 0px;
    left: unset !important;
}

.select2.select2-container.select2-container--default.select2-container--below.select2-container--focus {
    font-size: 12px !important;
    height: 32px !important;
}

.select2-box {
    height: 40px;
}

.material {
    width: 100% !important;
}

.select2-container--default .select2-selection--single {
    background: transparent !important;
}

.select2-container--default .select2-selection--multiple {
    background: transparent !important;
}

.material .select2-container--default .select2-selection__arrow {
    top: 5px !important;
}

.material .select2-container--default .select2-focused .select2-selection--multiple::after,
.material .select2-container--default .select2-focused .select2-selection--single::after,
.material .select2-container--default.select2-container--open .select2-selection--multiple::after,
.material .select2-container--default.select2-container--open .select2-selection--single::after {
    width: auto !important;
}

.material .select2-container--default .select2-selection--multiple::before,
.material .select2-container--default .select2-selection--single::before {
    width: auto !important;
}

.material .select2-container--default .select2-selection__arrow {
    top: 5px !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin-top: 0px !important;
    border: none !important;
    margin: 2px !important;
}

.mat-mdc-form-field {
    font-size: 12px !important;
    font-family: 'Source Sans Pro', sans-serif;
}

mat-step-header .mat-step-label,
.mat-step-label.mat-step-label-active.mat-step-label-selected,
.mat-step-label.mat-step-label-active {
    color: rgb(134, 124, 124);
    font-size: 14px;
    padding: 6px 0 0 0;
}

.mat-horizontal-stepper-header .mat-step-label {
    padding: 6px 0 0 0 !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
    padding: 24px 24px 0px 24px !important;
}

select2.mySelect2.ng-invalid[aria-invalid='true'] {
    border: 2px solid $color-red;
    border-radius: 5px;

    span.select2-selection__placeholder {
        color: $color-red !important;
    }
}

.full-screen-modal {
    .mat-mdc-dialog-container {
        max-width: none;
        padding: 24px;
        padding-top: 0px;
        padding-left: 0px;
    }
}

.cdk-overlay-dark-backdrop {
    background: #000;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.8;
}

.popupBackdropBackground {
    backdrop-filter: blur(4px);
    background: rgba(0, 0, 0, 0.288);
}

.mat-divider {
    border: 1px solid $sidebar-selected-item;
}

.text-danger {
    color: #f05050;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-indeterminate.mat-accent .mdc-checkbox__background,
.mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background {
    background-color: $sidebar-selected-item !important;
    border: none;

    .mdc-checkbox__checkmark {
        color: #ffffff !important;
        width: 13px;
        height: 13px;
        margin: auto;
    }
}

.mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__outer-circle {
    border-color: $sidebar-selected-item !important;
}

.mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle {
    border-color: $sidebar-selected-item !important;
}

/* dark theme fixes */
.mdc-checkbox {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-frame {
        border-color: rgba(0, 0, 0, 0.54) !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-checkmark-path {
        stroke: #fafafa !important;
    }
}

.mdc-radio__background {
    .mdc-radio__outer-circle {
        border-color: rgba(0, 0, 0, 0.54);
    }
}

.mat-form-field-appearance-outline {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-outline {
        color: rgba(0, 0, 0, 0.12);
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label,
    .mat-mdc-form-field-hint {
        color: rgba(0, 0, 0, 0.6);
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-fill .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.42);
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-slide-toggle-label {
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-slide-toggle-bar {
        background-color: rgba(0, 0, 0, 0.38);
    }
}

/* dark theme fixes */
.fai {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    font-size: 19px;
}

.display-inline-block {
    display: inline-block;
}

.visibilityButtonIcon {
    bottom: 2px;
    margin-left: 10px;

    .fas {
        font-size: 16px;
        font-weight: 500;
        color: black;
    }
}

.custom-mod-select {
    .mat-button-toggle-checked {
        background: white;
        border: solid 1px $sidebar-selected-item !important;

        .circle {
            border: 1px solid $sidebar-selected-item !important;
        }

        .right-circle {
            background-image: linear-gradient(90deg, white 50%, transparent 50%),
                linear-gradient(90deg, $sidebar-selected-item, 100%, transparent 0%) !important;
        }

        .full-circle {
            background-image: linear-gradient(90deg, $sidebar-selected-item, 100%, transparent 0%) !important;
        }

        .left-circle {
            background-image: linear-gradient(-90deg, white 50%, transparent 50%),
                linear-gradient(90deg, $sidebar-selected-item, 50%, transparent 50%) !important;
        }
    }
    .mat-button-toggle .mat-button-toggle-checked {
        border: solid 1px $sidebar-selected-item !important;
        background: white;
    }
}

.visibilityDisable {
    width: 0px !important;
    height: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
    border: none;
    line-height: 0px !important;
    .mat-mdc-text-field-wrapper,
    .mat-mdc-form-field-flex,
    .mat-mdc-form-field-infix {
        padding: 0px;
        width: 0px;
        height: 0px;
        left: -42px;
        border: none;
        z-index: -100000;
        .mdc-notched-outline {
            display: none;
        }
    }
    .mat-mdc-select-arrow-wrapper {
        display: none;
    }
}

.owl-dt-inline-container,
.owl-dt-popup-container {
    width: 26.5em !important;
}

.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
    height: 28.25em !important;
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
    font-size: 1.5em !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-content {
    font-size: 1.2em !important;
}

.owl-dt-container-info .owl-dt-container-range .owl-dt-container-range-content {
    font-size: 1.3em !important;
}

.owl-dt-container-control-button {
    font-size: 1.3em !important;
}

#tsep-cardNum,
#tsep-datepicker,
#tsep-cvv2,
#zipcode {
    display: block;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    line-height: 1.428571429;
    color: #3a3f51;
    background-color: #ffffff;
    margin-bottom: 20px;
    background-image: none;
    border: 1px solid #dde6e9;
    box-shadow: none;
    border-radius: 5px;
    font-size: 14px;
}

.showfileds {
    border: 1px solid #dedede;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0;
}

#payment-form {
    .label-field {
        line-height: 30px;
        font-weight: 600;
    }
}

.paymentSelectorBtns {
    margin-top: 10px;
}

.add-company-dialog h3 {
    margin-top: 0;
}

.pay-now .enable-btn {
    float: right;
}

//user list circle
.userImg {
    display: inline-block;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-position: center center;
    background-size: cover;
    position: relative;
}

.userImg .profile-pic {
    min-height: 30px;
    min-width: 34px;
    max-height: 30px;
    max-width: 34px;
    overflow: hidden;
    border-radius: 50px;
}

.userPlus {
    margin-top: 5px;
    vertical-align: top;
    margin-left: 15px;
}

.circle.circle-success.circle-lg {
    background-color: #27c24c;
    display: inline-block;
    margin: -10px 0 0px -9px;
    width: 7px;
    position: absolute;
    height: 7px;
    left: 10px;
    top: 0;
    border-radius: 500px;
    vertical-align: baseline;
    border: 2px solid transparent;
}

.circle.circle-warning.circle-lg {
    background-color: #ffea2b;
    display: inline-block;
    margin: -10px 0 0px -9px;
    width: 7px;
    position: absolute;
    height: 7px;
    left: 10px;
    border-radius: 500px;
    vertical-align: baseline;
    border: 2px solid transparent;
}

.red-dark,
.blue-dark,
.green-dark,
.purple-dark,
.indigo-dark {
    .mat-drawer-container {
        color: black;
    }
}

.header-icon {
    font-size: 23px !important;
}

.custom-item-btn {
    border: 1px solid lightgrey;
    margin-right: 10px;
    min-width: 30px;
    padding: 1px 8px;
    line-height: 29px;
    border-radius: 0px;
}

.tag-btn {
    font-size: 12px;
    font-weight: 500;
    height: 24px;
    min-height: 24px;
    border-radius: 3px;
    padding: 0px 5px !important;
    background-color: transparent;
    text-transform: uppercase;
    cursor: pointer;
}

.progressButton .ng-select-container,
.successButton .ng-select-container,
.dangerButton .ng-select-container,
.warningButton .ng-select-container {
    border: none;
    color: inherit;
    background-color: transparent;
}

.progressButton .ng-select-container .ng-value-container .ng-placeholder,
.successButton .ng-select-container .ng-value-container .ng-placeholder,
.dangerButton .ng-select-container .ng-value-container .ng-placeholder,
.warningButton .ng-select-container .ng-value-container .ng-placeholder {
    color: inherit;
}

.progressButton .ng-arrow-wrapper .ng-arrow {
    border-color: #0000ff transparent transparent;
}

.successButton .ng-arrow-wrapper .ng-arrow {
    border-color: #00963c transparent transparent;
}

.dangerButton .ng-arrow-wrapper .ng-arrow {
    border-color: #c81010 transparent transparent;
}

.warningButton .ng-arrow-wrapper .ng-arrow {
    border-color: #f35d05 transparent transparent;
}

.progressButton {
    color: #0000ff;
    border: 1px solid #0000ff;
}

.progressButton:hover {
    color: #0000ff;
    background-color: #d8d8ff;
}

.successButton {
    color: rgb(0, 150, 60);
    border: 1px solid rgb(0, 150, 60);
}

.successButton:hover {
    color: rgb(0, 150, 60);
    background-color: rgba(0, 150, 60, 0.253);
}

.dangerButton {
    color: rgb(200, 16, 16);
    border: 1px solid rgb(200, 16, 16);
}

.dangerButton:hover {
    color: rgb(200, 16, 16);
    background-color: rgba(200, 16, 16, 0.253);
}

.warningButton {
    color: #f35d05;
    border: 1px solid #f35d05;
}

.warningButton:hover {
    color: #f35d05;
    background-color: #f35c0542;
}

.primaryButton {
    color: #056cf3;
    border: 1px solid #056cf3;
}

.primaryButton:hover {
    color: #056cf3;
    background-color: #056cf344;
}

.secondaryButton {
    color: #3a3a3a;
    border: 1px solid #3a3a3a;
}

.secondaryButton:hover {
    color: #3a3a3a;
    background-color: #3a3a3a3b;
}

.voiletButton {
    color: #9b00e2;
    border: 1px solid #9b00e2;
}

.voiletButton:hover {
    color: #9b00e2;
    background-color: #9a00e241;
}

.btn_deferrd {
    color: grey;
    border: 1px solid grey;
}

.btn_deferrd:hover {
    color: grey;
    background-color: #e8e6dc;
}

.btn_warning_to {
    color: orange;
    border: 1px solid orange;
}

.btn_warning_to:hover {
    color: orange;
    background-color: #ffe0b3;
}

mat-divider {
    margin-top: -1px;
}

.img-inline {
    display: inline-block;
}

// .mat-tab-body-content {
//   overflow: hidden!important;
// }
.mdc-list-item__content {
    line-height: 1 !important;
    padding: 10px;
}

.mat-mdc-menu-content:not(:empty) {
    padding: 10px !important;
}

.mat-mdc-menu-content:not(:empty),
.headMenu mat-form-field-flex {
    padding: 5px 0 !important;
}

.mat-mdc-menu-content mat-form-field {
    padding: 0 10px;
}

.headMenu .mdc-list-item__content {
    line-height: 0 !important;
    padding: 10px;
}

.headMenu .mat-mdc-menu-content .mat-mdc-form-field,
.headMenu .mat-mdc-menu-content button.mat-mdc-menu-item {
    padding: 0 10px;
}

.mat-mdc-menu-content button.mat-mdc-menu-item .mat-icon {
    margin: 0;
    padding: 3px;
}

.headMenu .mdc-list-item__content:hover {
    background-color: #f2f4f6;
}

/*Support Module css*/
.participantsValue .img-inline,
.participantsValue a,
.participantsValue .text-info i {
    position: relative;
    left: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
#opp-cont-dev .list-grid-btn.mat-button-toggle-group .mat-button-toggle,
#projects-cont-dev .list-grid-btn.mat-button-toggle-group .mat-button-toggle,
#tickets-cont-dev .list-grid-btn.mat-button-toggle-group .mat-button-toggle {
    margin-right: 10px;
    border: solid 1px rgba(0, 0, 0, 0.12);
}

.list-grid-btn_column.kanban .grid_view {
    display: none;
}

.list-grid-btn_column.list_view_enable .list_view {
    display: none;
}

.participantsValue span.circle {
    left: 20px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.list-grid-btn.mat-button-toggle-group .mat-button-toggle,
.list-grid-btn.mat-button-toggle-group .mat-button-toggle {
    margin-right: 10px;
    border: solid 1px rgba(0, 0, 0, 0.12);
    margin-left: 10px;
    margin-top: 2px;
}

.apply-filter-btn {
    border-radius: 20px;
    margin-right: 10px;
    background-color: $color-green !important;
    font-weight: 600;
}

.reset-filter-btn {
    border-radius: 20px;
    margin-right: 10px;
    background-color: #e0e0e0 !important;
    color: #000 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-mdc-tab-body .list-grid-btn.mat-button-toggle-group,
.mat-mdc-tab-body .list-grid-btn.mat-button-toggle-group {
    border: none;
    position: relative;
    bottom: 3px;
    right: 5px;
}

/** to change list and grid view button from opportunity/tickits/project **/
.list-grid-btn_column.list_view_enable {
    position: absolute;
    z-index: 1000;
    left: 160px;
    top: 2px;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.list-grid-btn_column.list_view_enable .list-grid-btn.mat-button-toggle-group {
    top: -5px;
}

#tickets_details .left_labels {
    line-height: 25px;
}

.search-filter-box {
    position: absolute;
    top: -46px;
    left: 50px;
    padding-left: 0px;
}

/** to change list and grid view button from opportunity/tickits/project **/
.dangerButton {
    margin: 6px 0;
}

.support-filter button.custom-item-btn {
    margin-left: 8px !important;
    position: relative;
    bottom: 3px;
    height: 34px;
}

.kanban-search-box .pipeline-search-box .mySelect2.material {
    width: 200px !important;
    float: right;
}

#opportunityCom .support-filter button.custom-item-btn {
    bottom: 7px;
}

.select2-results__options .select2-results__option {
    padding: 4px 6px;
}

@media (max-width: 1499px) and (min-width: 1300px) {
    .kanban-search-box .pipeline-search-box .mySelect2.material {
        position: relative;
        left: 20%;
    }
}

@media (max-width: 1600px) and (min-width: 1500px) {
    .kanban-search-box .pipeline-search-box .mySelect2.material {
        position: relative;
        left: 30%;
    }
}

@media (min-width: 1601px) {
    .kanban-search-box .pipeline-search-box .mySelect2.material {
        position: relative;
        left: 35%;
    }
}

.kanban-search-box {
    top: -45px;
}

.table-fields .custom-item-btn.mat-mdc-button {
    position: relative;
    bottom: 2px;
    margin-top: 2px;
}

#opportunityCom .kanban-search-box .search {
    position: relative;
    top: 0px;
}

#overdue button.search {
    position: relative;
    top: -2px;
}

#opportunityCom .search.custom-item-btn {
    position: relative;
    margin-top: 2px;
}

.search.custom-item-btn,
.list-search-box .custom-item-btn {
    line-height: 30px;
}

#opportunityCom #opp-cont-dev .support-filter button.custom-item-btn {
    bottom: 7px;
}

#opportunityCom #opp-cont-dev .search {
    position: relative;
    top: 1px;
}

#opportunityCom #opp-cont-dev .search.custom-item-btn {
    margin-top: 0px;
}

.filter_btns {
    width: 50%;
    float: left;
    margin-top: 5px;
    margin-bottom: 5px;
}

.userName-btn mat-icon {
    position: relative;
    top: 4px;
    left: 5px;
}

.mat-mdc-menu-panel .mdc-list-item__primary-text i {
    width: 14px;
}

.createDropdown {
    width: 500px;
    max-width: 420px !important;
    right: 15px;
    position: absolute;
    top: 5px;
    padding: 5px 0px;
}

@media only screen and (max-width: 768px) {
    .createDropdown {
        width: 250px;
        max-width: 250px !important;
        right: 15px;
    }

    #headLeft {
        text-align: right;
        padding: 0px 1px 5px 15px !important;
        margin-top: 50px !important;
    }

    //toolbar search box width
    .search-bar {
        width: 90%;

        .header-search {
            width: 90%;
        }
    }

    .header_site_name mat-label {
        width: 40%;
        line-height: 16px;
    }

    .mat-toolbar-single-row {
        height: 50px !important;
    }

    .header_site_name mat-label {
        width: 80% !important;
    }

    .search-bar .header-search {
        width: 100% !important;
    }

    .header-select,
    .header-search {
        height: 35px !important;
    }

    .search-bar .search_form {
        width: 135%;
    }
}

.createDropdown .mat-mdc-menu-content {
    padding: 0px !important;
}

.createDropdown mat-list-item.headMenu {
    padding: 3px 10px;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    color: #333;
}

.menuSplit {
    display: inline-block;
    border-bottom: 1px solid #ddd;
    width: 100%;
    margin-bottom: -5px;

    &:last-child {
        border-bottom: none;
    }
}

/*Dashboard css*/
#dashboardData .mat-mdc-tab-header .mat-mdc-tab-list {
    .mat-mdc-tab-labels {
        align-items: center;
        height: 100%;
    }

    .mat-mdc-tab {
        max-width: fit-content;

        &:first-child {
            padding: 0;
        }
    }
}

#dashboardData .mat-mdc-paginator-page-size-label {
    display: none;
}

.mat-grid-tile .mat-grid-tile-content {
    margin-left: auto;
    margin-right: auto;
    justify-content: left;
}

@media (min-width: 1350px) {
    .three.mat-grid-tile .mat-grid-tile-content,
    .four.mat-grid-tile .mat-grid-tile-content {
        padding-top: 5%;
    }
}

.one.mat-grid-tile .mat-grid-tile-content,
.two.mat-grid-tile .mat-grid-tile-content,
.three.mat-grid-tile .mat-grid-tile-content,
.four.mat-grid-tile .mat-grid-tile-content {
    line-height: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
#attachmentCom .mat-mdc-tab-labels,
.pad0 {
    padding: 0;
}

.mat-elevation-z8 .table-fields .list-search-box {
    position: relative;
    top: 0px;
}

#overdue .CallsDetail div.search,
#overdue .leadsMeetingsDetail div.search {
    top: 0;
}

#themeDiv #overdue .mat-column-name {
    width: 280px;
    padding-right: 10px;
}

.pinnedProgress {
    left: 8px;
    position: relative;
    float: right;
    background-color: transparent !important;
    color: #0000ff !important;
    bottom: 3px;
}

.pinnedProgress:hover {
    color: #0000ff;
    background-color: #d8d8ff;
}

#projects-cont-dev .list-search-box,
#inv-cont-dev .search {
    position: relative;
    top: 1px;
}

#inv-cont-dev #invCom .search {
    position: relative;
    top: 0px;
}

#overdue .mat-mdc-tab-body.ng-tns-c42-81 div.search,
#overdue .mat-mdc-tab-body.ng-tns-c42-82 div.search {
    position: relative;
    top: 0px;
}

.organization-details {
    .row.userImg {
        margin-left: -2px;
    }

    .plus-icon {
        vertical-align: text-bottom;
        padding: 5px 18px;
    }
}

#overdue .table-fields {
    padding: 5px 10px 5px 15px !important;
}

ng-scrollbar-y.ng-scrollbar-visible {
    padding: 0;
    position: absolute;
    right: 0;
}

.hight_box.mat-mdc-list-item:nth-child(even) {
    background-color: #f9f9f9;
}

.mat-expansion-panel-body .width65 {
    width: 90% !important;
}

.mat-expansion-panel-body .right_value {
    float: right !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
#purchaseOrderQty .mat-mdc-text-field-wrapper {
    bottom: 7px;
    margin-left: -1px;
    margin-right: -1px;
}

#purchaseOrderQty .mat-form-field-appearance-outline .mat-mdc-form-field-flex {
    margin-top: 0px;
}

#invoiceQty .mat-mdc-text-field-wrapper {
    bottom: 0;
}

#estimateQty .mat-mdc-text-field-wrapper {
    bottom: 8px;
}

.cdk-overlay-container .cdk-overlay-pane .mat-mdc-select-panel {
    max-width: none !important;
}

.filter-clinetName .material .select2-selection__arrow {
    top: 2px !important;
}

.filter-clinetName .material .select2-container {
    padding-bottom: 2px !important;
}

.winRatioDB.mat-grid-tile .mat-grid-tile-content {
    position: absolute;
    top: -45px;
}

#statisticsDB .mat-grid-tile {
    overflow: visible;
}

.goalsBox.width65.below .mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
    padding: 0;
}

.mat-expansion-panel-header {
    padding: 0 15px;
    border-bottom: 1px solid #e0e0e0;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-expansion-panel-body .mat-form-field-underline {
    height: 0;
}

.col-md-6 .goalsBox.width65.below {
    margin-left: 10px;
}

.pd-top10 {
    padding-top: 10px;
}

.pd-top25 {
    padding-top: 25px;
}

.top2 {
    position: relative;
    top: 2px;
}

.flRight {
    float: right;
}

.poRelative {
    position: relative;
}

.cdk-overlay-pane {
    .mat-mdc-select-panel {
        min-width: 70px;
    }

    .mdc-list-item__primary-text {
        font-size: 14px !important;
        font-family: Roboto, 'Helvetica Neue', sans-serif !important;
        font-weight: 500 !important;
    }
}

.mySelect2.statistics-data .select2-selection {
    padding: 5px;
}

/*hr*/
.detail-view-title {
    position: relative;
    top: 3px;
}

.estimate-tick.filter-option .mat-pseudo-checkbox {
    display: none;
}

button.btn.btn-mini.greenButton {
    &:hover {
        color: #fff;
        background-color: #35c748;
    }

    &:focus {
        color: #fff;
        background-color: #35c748;
    }
}

button.btn.btn-mini.redButton {
    &:hover {
        color: #fff;
        background-color: #c81010;
    }

    &:focus {
        color: #fff;
        background-color: #c81010;
    }
}

.payment-paginator .mat-mdc-paginator-range-actions {
    display: block;
    align-items: center;
}

.payment-paginator .mat-mdc-paginator-page-size {
    display: flex;
    align-items: baseline;
    margin-top: -7px;
}

.payment-paginator .mat-mdc-paginator-range-label {
    margin-bottom: -30px;
    margin-left: -74px;
}

.enable-btn.msg-btn {
    border-radius: 30px;
    float: none;
    margin: 0 auto;
    display: block;
}

/*Settings*/
.settingTab .mdc-tab__content .mat-icon {
    height: auto;
}

.httpsText {
    font-size: 12px;
    text-align: center;
    position: relative;
    bottom: 0;
}

.working-form-div .col-md-3 .form-group.form-default {
    float: left;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.checkboxEmail label.mat-checkbox-layout {
    padding-right: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.checkboxEmail label.mat-checkbox-layout {
    padding-right: 20px;
}

#emailcheck .mat-mdc-text-field-wrapper {
    bottom: 3px;
}

.payment-details .payment-page .mat-mdc-paginator-page-size-label {
    display: none;
}

.close-back mat-icon.mat-icon {
    height: 20px;
}

.f-r {
    float: right;
}

.details-column p {
    word-wrap: break-word !important;
}

.mat-mdc-option {
    font-weight: 500;
}

.mat-mdc-option,
.mat-mdc-optgroup-label,
.mat-mdc-menu-item,
.mat-sort-header-content,
.mat-mdc-header-cell {
    color: #000000 !important;
}

#project_details .detail_section .top3 strong,
#tickets_details .detail_section .top3 strong,
.detail_section strong span,
.detail_section .ticket_status_button {
    top: 4px;
    position: relative;
}

#estimateQty .mat-form-field-appearance-outline .mat-mdc-form-field-flex {
    padding: 0px 8px;
    margin-top: 1px;
    margin-left: -1px;
    width: 100px;
}

#invoiceQty .mat-form-field-appearance-outline .mat-mdc-form-field-flex {
    margin-top: -3px;
    margin-left: -1px;
    width: 100px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
#emailcheck label.mat-form-field-label {
    background-color: #fff;
    padding: 0 10px;
    width: 63px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
#emailcheck .mat-form-field-should-float.mat-focused label.mat-form-field-label {
    padding: 0 7px;
    width: 60px;
}

#emailcheck mat-form-field.noError {
    border: none;
}

.disabledClass {
    pointer-events: none;
}

div#emailcheck {
    margin-top: 3px;
}

#emailcheck .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
    margin-top: -5px;
    height: 48px;
    width: 101%;
    margin-left: -2px;
}

#emailcheck mat-form-field.errorClass {
    margin-top: -2px;
}

#tenantCom hr.hr-class {
    margin-top: 0px;
    position: relative;
    top: 2px;
}

#tenantCom button.search {
    bottom: 0px;
}

.tenantHeading {
    display: inline-block;
    padding: 8px 0;
}

#statisticsDB .custom-select.ng-select-single.ng-select .ng-select-container {
    height: 37px;
}

#statisticsDB .custom-select .ng-has-value .ng-placeholder {
    -webkit-transform: translateY(-1.29355em) scale(0.7) translateX(-2em);
    -ms-transform: translateY(-1.29355em) scale(0.7) translateX(-2em);
    transform: translateY(-1.29355em) scale(0.7) translateX(-2em);
}

#dashboardData mat-paginator.payment-paginator .mat-mdc-paginator-page-size {
    width: 24%;
}

#dashboardData mat-paginator.payment-paginator .mat-mdc-paginator-range-actions {
    width: 72%;
    text-align: right;
}

#dashboardData mat-paginator.payment-paginator .mat-mdc-paginator-range-label {
    margin-bottom: -30px;
    margin-left: -28px;
    text-align: left;
}

#dashboardData mat-paginator.payment-paginator .mat-mdc-paginator-range-actions button {
    text-align: right;
}

#dashboardData mat-paginator.payment-paginator .mat-mdc-paginator-page-size-select {
    width: 55px;
}

#dashboardData .bigCard mat-paginator.payment-paginator .mat-mdc-paginator-range-label {
    margin-left: -70px;
}

.tastNewNotes {
    position: relative;
    bottom: 5px;
}

.reduce .mat-mdc-tab-body-content .panel {
    margin-bottom: 0px;
}

.select2-results__option {
    font-weight: 400;
}

.top3 {
    position: relative;
    top: 3px;
}

.bot3 {
    position: relative;
    bottom: 3px;
}

.invoiceDate .mat-mdc-form-field-flex {
    height: 43px;
}

#invCom .applyButton {
    background-color: $color-green;
    color: #fff;
}

#invCom .declineButton {
    color: #fff;
    background-color: rgb(200, 16, 16);
}

.estimatePopup.mat-mdc-menu-panel {
    min-width: calc(100% + 90px) !important;
    max-width: 320px !important;
    min-height: 375px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.estimatePopup .mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background {
    background: #5b4fa7 !important;
}

.estimatePopup mat-mdc-checkbox label {
    font-weight: 500;
}

.estimatePopup .mat-mdc-checkbox.cdk-focused {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87);
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.estimatePopup label.mat-checkbox-layout {
    margin: 0;
}

.bot4 {
    bottom: 4px;
    position: relative;
}

.projectDetails .new-contact-div {
    width: 100%;
}

.projectDetails #attachmentCom .content {
    padding: 0;
}

#bugs mat-card.mat-mdc-card {
    margin: 15px 0;
}

#bugs .container.full-width {
    padding-left: 0px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.bugTitle .mat-form-field-outline {
    height: 42px;
}

.bugDetails #attachmentCom mat-tab-header.mat-mdc-tab-header {
    padding: 8px 15px !important;
}

.bugDetails #attachmentCom .content {
    padding: 0px;
}

.bugDetails #attachmentCom .new-contact-div,
#tickAttachment .new-contact-div {
    width: 100%;
    margin-left: 15px;
}

#attachmentCom .mat-mdc-tab .mdc-tab__content,
.clientContactDetail .mat-mdc-tab .mdc-tab__content {
    font-weight: 600;
}

#timesheet .new-contact-div {
    margin-top: 15px;
}

.custom-select {
    .ng-select-container {
        position: initial !important;
    }

    .ng-placeholder {
        transition: 300ms;
        font-size: 12px;
        font-weight: 300 !important;
        color: #3d3d3d !important;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        padding: 4px 8px;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
        padding-left: 20px;
        font-size: 12px;
    }

    .ng-has-value .ng-placeholder {
        position: absolute;
        background-color: #fff;
        display: block;
        -webkit-transform: translateY(-1.79355em) scale(0.75) translateX(-1.5em);
        -ms-transform: translateY(-1.79355em) scale(0.75) translateX(-1.5em);
        transform: translateY(-1.79355em) scale(0.75) translateX(-1.5em);
        padding: 0 5px;
    }

    .ng-option-label,
    .ng-placeholder {
        font-family: 'Source Sans Pro', sans-serif;
    }

    .ng-value-label {
        font-size: 12px;
        font-weight: 300 !important;
        color: #000;
    }

    .ng-option-label {
        font-size: 12px;
    }

    .ng-option.ng-option-selected,
    .ng-option.ng-option-selected.ng-option-marked {
        background-color: transparent !important;
    }
}

.participantspopup {
    backdrop-filter: blur(4px);
    background: rgba(0, 0, 0, 0.288);
}

.participantspopup + * > .cdk-overlay-pane .mat-mdc-dialog-container {
    overflow: visible !important;
}

.custom-select .ng-option-label,
.custom-select .ng-option {
    font-size: 12px;
    font-weight: 400 !important;
    color: #000 !important;
}

.custom-select .ng-option.ng-option-selected .ng-option-label,
.custom-select .ng-option.ng-option-selected {
    color: #ff5722 !important;
}

.custom-select .ng-option.ng-option-selected,
.custom-select .ng-option.ng-option-selected.ng-option-marked {
    background-color: transparent !important;
}

.ng-option-disabled.ng-optgroup {
    font-weight: 700 !important;
    color: gray !important;
    font-size: 11px;
    padding: 6px 5px !important;
}

.custom-select .ng-option.ng-option-disabled .ng-option-label,
.custom-select .ng-option.ng-option-disabled {
    color: gray !important;
}

.custom-select.multiple-select .ng-has-value .ng-placeholder {
    left: 20px;
    border: none;
}

.errorClass .ng-placeholder {
    color: $color-red !important;
}

.custom-select .noError,
.custom-select select2.custom-select.ng-valid,
.noError .ng-select-container,
.errorClass .ng-select-container {
    border: none !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border: 1px solid $color-light-gray;
    box-shadow: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 13px;
}

.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container .ng-placeholder {
    top: 12px;
    left: 14px;
    display: block;
}

.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
    height: 40px;
}

/*.mat-form-field-appearance-outline .mat-form-field-outline,*/
.custom-select.ng-select-multiple.ng-select .ng-select-container,
.custom-select.multiple-select.ng-select .ng-select-container,
.custom-select.ng-select-single.ng-select .ng-select-container {
    height: 44px;
    overflow: scroll;
}

.participants_form .options_dropdown .custom-select.ng-select-multiple.ng-select .ng-select-container {
    height: auto;
    min-height: 44px;
}

.pipeline-search-box .custom-select.ng-select-single.ng-select .ng-select-container {
    height: 34px;
    background: transparent;
}

.pipeline-search-box .custom-select.ng-select-single.ng-select .ng-select-container:hover {
    box-shadow: unset;
}

.pipeline-search-box .custom-select.ng-select-single.ng-select .ng-select-container.ng-has-value .ng-placeholder {
    padding: 0 6px;
    margin-top: 3px;
    margin-left: 10px;
}

.staTile .mat-mdc-list-item {
    border-color: $sidebar-selected-item !important;
}

.statistics-tab.statistics-button {
    background-color: $sidebar-selected-item !important;
    color: white !important;
}

.form-success {
    background-color: $sidebar-selected-item !important;
    color: white !important;
}

i.far.fa-envelope {
    color: #337ab7;
    font-size: 16px;
}

.paymentLeft {
    padding-right: 0;
    padding-left: 0;
    left: 16px;
}

.payment-page mat-paginator.payment-paginator .mat-mdc-paginator-range-actions button.mat-mdc-icon-button {
    width: 30px;
}

.payment-page .payment-paginator .mat-mdc-paginator-range-label {
    margin-left: -130px;
}

.recPayment {
    margin-bottom: 35px;
    text-align: center;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
.recPayment .mat-card-header-text {
    width: 100%;
}

.paymentData {
    margin-left: -10px;
    font-size: 14px;
    position: relative;
    padding: 0px 50px !important;
}

.paymentAmount {
    text-align: center;
    background-color: #1eacbe;
    padding: 30px 70px;
    color: #ffffff;
}

.paymentAmount .currency {
    font-size: 25px;
    margin-top: 10px;
}

mat-card-content.paymentData.mat-mdc-card-content {
    font-size: 14px;
}

.paymentFor {
    margin: 0 10px 10px 10px;
    width: 100%;
}

.paymentFor .mat-mdc-card-subtitle {
    margin-left: 7px;
    font-weight: 700;
    color: #000;
    font-size: 15px;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
paymentRight button.mat-button-wrapper {
    position: relative;
    bottom: 3px;
}

/*.material .select2-container--default .select2-selection--multiple .select2-selection__placeholder,*/
.material .select2-container--default .select2-selection--single .select2-selection__placeholder {
    -webkit-transform-origin: 0 1px !important;
    transform-origin: 0 1px !important;
}

select2#salesRep {
    margin-top: 10px;
}

.createPOform .itemnamecell .ng-select-container,
#poTaxBox .ng-select-container {
    height: 42px !important;
}

#poTaxBox {
    padding-right: 10px;
    max-width: 50px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.bugTitle .mat-form-field-appearance-outline .mat-form-field-outline {
    min-height: 46px !important;
}

#tickAttachment .attachmentListName {
    padding: 15px 10px;
}

.attachmentListName {
    padding: 15px;
}

.att-list_delete,
.attListEdit {
    padding: 15px 10px;
}

.att-list_delete i.fai {
    padding: 0;
}

.attachment.attachment-list span.mat-expansion-indicator {
    padding: 15px;
    position: relative;
    bottom: 3px;
}

.attListEdit .edit-bug {
    font-size: 17px;
}

.text-primary.uploadFileName,
.mailbox-attachment-size span {
    display: block;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.uploadAttacment {
    display: inline-block;
    width: 150px;
    float: left;
    border: 1px solid #eee;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 15px;
}

.upFile {
    margin: 0;
}

.mailbox-attachment-icon.has-img {
    padding: 4px;
    display: block;
}

.mailbox-attachment-info.clearfix {
    padding: 0 0 5px 5px;
    background: #f4f4f4;
    border-top: 1px solid #dbd9d9;
}

.mailbox-attachment-size .btn-default {
    font-size: larger;
    color: gray;
    height: 26px;
    margin-top: 5px;
}

.mailbox-attachment-size i {
    position: relative;
    bottom: 2px;
}

.notFound {
    text-align: center;
    padding-top: 5px;
}

.notFound span {
    margin-top: 10px;
    color: #000;
    font-style: normal;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}

.disabledClass .ng-select-container {
    opacity: 0.5;
    background-color: lightgray;
    pointer-events: none;
}

#adminClientCom .mat-horizontal-content-container {
    padding: 0;
}

.filter-clinetName-estimates.EstimateList .custom-select .ng-has-value .ng-placeholder {
    -webkit-transform: translateY(-1.79355em) scale(0.75) translateX(-2.3em);
    -ms-transform: translateY(-1.79355em) scale(0.75) translateX(-2.3em);
    transform: translateY(-1.79355em) scale(0.75) translateX(-2.3em);
}

.custom-item-btn i {
    width: 16px;
    font-size: 16px;
}

.clientNoteDetail .mdc-tab__content {
    font-weight: 700 !important;
}

/** new ng select */
/*ng-select  for leads status and opportunity status listing */
.mySelect2-opportunity-status.ng-select,
.mySelect2-leads-status.ng-select {
    display: inline-block;
    position: relative;
    font-size: 12px;
}

.mySelect2-opportunity-status.ng-select.ng-select-single .ng-select-container,
.mySelect2-leads-status.ng-select.ng-select-single .ng-select-container {
    background: #5cb85b;
    width: 90px;
    height: 28px;
    min-height: 30px;
}

.mySelect2-opportunity-status.ng-select.ng-select-single .ng-select-container .ng-value-container,
.mySelect2-leads-status.ng-select.ng-select-single .ng-select-container .ng-value-container {
    color: #fff;
    font-weight: 600;
    padding-left: 5px;
}

.mySelect2-opportunity-status.ng-select.ng-select-single .ng-select-container .ng-placeholder,
.mySelect2-leads-status.ng-select.ng-select-single .ng-select-container .ng-placeholder {
    color: #fff;
}

.mySelect2-opportunity-status.ng-select.ng-select-opened > .ng-select-container .ng-arrow,
.mySelect2-leads-status.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
    border-color: transparent transparent #fff;
    border-width: 0 4px 4px;
}

.mySelect2-opportunity-status.ng-select .ng-arrow-wrapper .ng-arrow,
.mySelect2-leads-status.ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: #fff transparent transparent;
    border-width: 4px 4px 2.5px;
}

.mySelect2-opportunity-status.ng-select .ng-dropdown-panel,
.mySelect2-leads-status.ng-select .ng-dropdown-panel {
    position: absolute;
    width: 180px;
    right: 0;
    left: unset;
}

.mySelect2-opportunity-status .ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.mySelect2-leads-status .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 6px 6px !important;
}

.mySelect2-opportunity-status .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
.mySelect2-leads-status .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    padding: 5px 5px !important;
}

// .custom-select .ng-dropdown-panel{min-height:200px;}
/** new ng select */

app-estimate-detail .morebtn .mat-icon {
    height: 19px;
}

.leadCustomSelect .custom-select.multiple-select {
    margin-top: 15px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.clientContactDetail .paddingInv.discount .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper .mat-form-field-outline {
    height: 44px;
    margin-top: 1px;
}

.clientContactDetail .paddingInv.dateInvoice .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper .mat-form-field-outline {
    height: 44px;
}

.createInvoiceForm table .mat-column-item_desc .mat-mdc-form-field .mat-form-field-outline,
.createInvoiceForm table .mat-column-unit_price .mat-mdc-form-field .mat-form-field-outline {
    height: 44px;
    margin-top: -3px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.createInvoiceForm table .mat-column-item_desc .mat-mdc-form-field .mat-mdc-form-field-infix {
    position: relative;
    bottom: 8px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.createInvoiceForm table .mat-column-quantity .mat-mdc-form-field .mat-form-field-outline {
    height: 44px;
    margin-top: -2px;
}

.createInvoiceForm table .mat-column-quantity .mat-mdc-form-field .mat-mdc-form-field-infix input,
.createInvoiceForm table .mat-column-unit_price .mat-mdc-form-field .mat-mdc-form-field-infix input,
.createInvoiceForm table .mat-column-subTotal .mat-mdc-form-field input,
.createInvoiceForm table .mat-column-tax .mat-mdc-form-field input {
    position: relative;
    bottom: 0px;
}

.createInvoiceForm #invoiceQty .mat-form-field-appearance-outline .mat-mdc-form-field-flex {
    margin-top: 3px;
}

.createInvoiceForm table .mat-column-subTotal .mat-mdc-form-field .mat-form-field-outline,
.createInvoiceForm table .mat-column-tax .mat-mdc-form-field .mat-form-field-outline {
    height: 44px;
    margin-top: -3px;
    color: rgba(0, 0, 0, 0.2);
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.createInvoiceForm .mat-form-field-outline {
    height: 44px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.createPOform .inv-notes .mat-form-field-outline {
    height: auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.createInvoiceForm .paddingInv.dateInvoice .mat-mdc-text-field-wrapper .mat-form-field-outline {
    margin-top: 2px;
}

.createInvoiceForm .paddingInv.discount {
    margin-top: 3px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.createPOform .mat-form-field-outline {
    height: 44px;
}

.createPOform .paddingInv {
    padding-top: 7px;
}

.createPOform .paddingInv.discount {
    margin-bottom: 8px;
}

.lightboxOverlay.animation.fadeInOverlay {
    height: 100% !important;
}

.lightbox .lb-outerContainer.transition {
    height: 300px !important;
    min-width: 300px;
    max-width: 300px;
    background-color: transparent;
}

img.lb-image.animation.fadeIn {
    width: 610px !important;
    height: 610px !important;
    background-color: #ffffff;
    left: -52%;
    position: relative;
    object-fit: contain;
}

.lb-dataContainer {
    display: flex;
    vertical-align: middle;
    justify-content: flex-end;
    width: 520px !important;
}

.lb-closeContainer {
    float: right;
    margin-right: -35px;
}

.lb-details {
    display: none;
}

.lb-container .lb-nav {
    width: 610px !important;
    left: -155px !important;
    top: 165px !important;
}

.lb-nav a.lb-prev,
.lb-nav a.lb-next {
    opacity: 1 !important;
}

.lb-close {
    display: block;
    float: right;
    width: 30px;
    height: 30px;
    border: 2px solid black;
    border-radius: 50%;
    background: black !important;
    position: absolute;
    top: 0;
    margin-left: -17px;
    background-image: none !important;
    opacity: unset !important;

    &:after {
        content: '\2715';
        color: white;
        height: 30px !important;
        width: 30px;
        margin-top: 15px;
        position: relative;
        font-size: 30px;
        top: 13px;
    }
}

.lb-data .lb-close:hover {
    cursor: pointer;
    color: white;
    opacity: 1;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.ticketForm .mat-form-field-outline {
    height: 44px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.ticketForm .tick-addr .mat-form-field-outline {
    height: 57px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.taskForm .mat-form-field-outline {
    height: 44px;
}

.taskForm mat-slider {
    margin: 0;
    width: 100%;

    .mdc-slider__thumb {
        left: -16px;
    }
}

.respUser .custom-select.ng-select-single.ng-select .ng-select-container {
    height: 42px;
}

.relatedName .form-group.form-default {
    margin-bottom: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.coaForm .mat-form-field-outline {
    height: 44px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.coaForm .coaDesc .mat-form-field-outline {
    height: auto;
}

.coaForm .coaAccType {
    margin-top: 3px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.refInfo .mat-form-field-outline {
    background-color: rgba(211, 211, 211, 0.5);
    pointer-events: none;
}

.accItem .ng-select.ng-select-single .ng-select-container {
    height: 42px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.accItem .mat-form-field-outline {
    height: 44px;
}

.itemFormType.item-select-option {
    margin-bottom: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.detailsPage.projectDetails .mat-form-field-outline {
    height: 44px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.detailsPage.projectDetails .projAtcDesc .mat-form-field-outline,
.detailsPage.projectDetails .pmDesc .mat-form-field-outline {
    height: auto;
}

button.btn-cancel.mat-mdc-raised-button,
button.btn-submit.mat-raised-button {
    border-radius: 20px;
    margin: 1%;
    font-size: 1em;
    vertical-align: baseline;
}

button.btn-cancel.mat-mdc-raised-button {
    background-color: #dddddd;
    color: black;
}

.login-color,
.send-mail-btn {
    background-color: $sidebar-selected-item;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
.mat-card-header-text:after {
    border-color: $sidebar-selected-item;
}

button.btn-submit.mat-mdc-raised-button {
    background-color: $sidebar-selected-item;
    color: white;
}

.apply-filter-btn {
    border-radius: 20px !important;
    margin-right: 10px !important;
    background-color: $color-green !important;
    border-color: $color-green !important;
    border: 2px solid #27c346;
    color: white;
    font-size: 14px;
    font-weight: 500;
    padding: 0 16px;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    height: 36px;
}

.reset-filter-btn {
    border-radius: 20px !important;
    margin-right: 10px !important;
    background-color: #e0e0e0 !important;
    color: #000 !important;
}

.ticket_filter_option.mat-mdc-optgroup[ng-reflect-label='Project Status'],
.ticket_filter_option.mat-optgroup[ng-reflect-label='Users'],
.ticket_filter_option.mat-optgroup[ng-reflect-label='Bill Status'] {
    display: inline-block;
    vertical-align: top;
    width: auto;
    height: 260px;
    overflow-y: scroll;
}

#opportunityCom .oppFormRgt {
    margin-top: 13px !important;
}

.clientContactDetail .oppFormRgt {
    margin-top: 3px !important;
}

#opportunityCom .custom-select.ng-select-single.ng-select .ng-select-container,
.clientContactDetail app-opportunity-form .custom-select.ng-select-single.ng-select .ng-select-container {
    height: 44px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.clientContactDetail.clientContact .mat-form-field-outline,
#bank-cash-div .mat-form-field-outline,
.clientEstimateCreate .mat-form-field-outline {
    height: 44px;
}

.clientContactDetail .tickCompName .custom-select.ng-select-single.ng-select .ng-select-container {
    height: 44px !important;
}

.projectDetails app-estimate-form .mat-column-item_desc mat-form-field,
.projectDetails app-estimate-form .mat-column-unit_price mat-form-field,
.projectDetails app-estimate-form .mat-column-markup mat-form-field,
.projectDetails app-estimate-form .mat-column-subTotal mat-form-field,
.projectDetails app-estimate-form .mat-column-tax mat-form-field {
    position: relative;
    bottom: 2px;
}

.projectDetails app-purchase-order-form .mat-column-item_desc mat-form-field,
.projectDetails app-purchase-order-form .mat-column-unit_price mat-form-field {
    bottom: 2px !important;
}

// common filter
.list-filter-menu-inner {
    padding: 10px 15px;

    .filter-action-btns {
        margin-bottom: 15px;
        position: relative;
    }

    .filter-close-btn {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        display: inline-block;
    }

    .form-group {
        margin-bottom: 10px;
    }

    form {
        .mat-mdc-form-field {
            padding: 0 0;
            .mat-mdc-text-field-wrapper {
                margin: 0 0;
            }
        }
    }

    ng-select.custom-select {
        width: 320px;

        &.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
            padding-bottom: 0px;
        }
    }

    ng-select.custom-select.multi-select {
        .ng-select-container {
            height: auto;
            overflow: hidden;

            .ng-value-container {
                align-content: start;
                overflow: auto;
            }

            .ng-arrow-wrapper,
            .ng-clear-wrapper {
                align-self: start;
                margin-top: 10px;
            }
        }

        &.three-fields-dd {
            .ng-select-container .ng-value-container {
                min-height: 152px;
                max-height: 152px;
            }
        }

        &.two-fields-dd {
            .ng-select-container .ng-value-container {
                min-height: 120px;
                max-height: 120px;
            }
        }
    }
}

.list-filter-menu + * .mat-mdc-menu-panel {
    max-width: 768px;

    .mat-mdc-menu-content {
        padding: 0 0 !important;
    }
}

app-projects-kanban .mat-mdc-list-base .mat-mdc-list-item.mat-multi-line .mdc-list-item__content,
app-projects-kanban .mat-list-base .mat-list-option.mat-multi-line .mdc-list-item__content {
    padding-top: 0;
    padding-bottom: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
#taskDetail app-task-timesheet .mat-mdc-tab {
    border: none;
    border-radius: 20px;
}

#taskDetail app-task-timesheet .mat-mdc-tab .mdc-tab__content {
    font-weight: 600;
}

#taskDetail .task_timesheet mat-tab-header {
    width: 100%;
}

#taskDetail .mat-mdc-tab-body-wrapper {
    box-shadow: none !important;
}

.createTimeBtn.mat-mdc-raised-button,
.createOppoBtn.mat-raised-button {
    background-color: $sidebar-selected-item;
}

.ng2-tag-input__text-input {
    height: 18px !important;
}

.companyInfo textarea#companyAddress {
    line-height: 15px;
}

a.link-popup {
    outline: none;
}

.horizontal-line {
    border-bottom: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
#namecheck label.mat-form-field-label {
    background-color: #fff;
    padding: 0 10px;
    width: 162px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.vendor-namecheck#namecheck label.mat-form-field-label {
    width: 61px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
#namecheck .mat-form-field-should-float.mat-focused label.mat-form-field-label {
    padding: 0 8px;
    width: 148px;
}

#namecheck mat-form-field.noError {
    border: none;
}

div#namecheck {
    margin-top: 3px;
}

#namecheck .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
    margin-top: -8px;
    height: 48px;
    width: 101%;
    margin-left: -2px;
}

#namecheck mat-form-field.errorClass {
    margin-top: -2px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
#namecheck .errorClass .mat-form-field-label {
    color: $color-red;
}

.align-right {
    text-align: right;
}

.filter-green {
    color: #4caf50 !important;
    border: 1px solid #4caf50;
}

/* removed black border of delete button on list */
a.btn.btn-sm.btn-action.btn:active:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 0 !important;
}

/* media queries */
@media (min-width: 1440px) {
    #loader-oppo .opp_div .mat-column-name a,
    #loader-oppo .opp_div .mat-column-client a,
    #estimate-cont-dev .mat-mdc-table .mat-column-Client-Lead-Name a {
        max-width: 250px;
    }

    .opp_div .mat-column-name,
    .opp_div .mat-column-client,
    #estimate-cont-dev .mat-column-Client-Lead-Name {
        width: 30%;
    }

    .cdk-overlay-pane .mat-mdc-select-panel {
        max-height: 500px !important;
    }
}

@media (min-width: 1601px) {
    .fiveHundred.matcard_content .mat-grid-tile .mat-grid-tile-content {
        width: 60%;
    }

    .opp_div .mat-column-action,
    .lead_list .mat-column-Action {
        width: 15%;
    }

    .opp_div .mat-column-name,
    .opp_div .mat-column-client,
    #estimate-cont-dev .mat-column-Client-Lead-Name {
        width: 25%;
    }

    #loader-oppo .opp_div .mat-column-name a,
    #loader-oppo .opp_div .mat-column-client a,
    #estimate-cont-dev .mat-mdc-table .mat-column-Client-Lead-Name a {
        max-width: 300px;
    }
}

@media (min-width: 1800px) {
    .opp_div .mat-column-name,
    .opp_div .mat-column-client,
    #estimate-cont-dev .mat-column-Client-Lead-Name {
        width: 20%;
    }

    .opp_div .mat-column-action,
    .lead_list .mat-column-Action {
        width: 8%;
    }
}

@media (max-width: 1600px) and (min-width: 1440px) {
    .fiveHundred.matcard_content .mat-grid-tile .mat-grid-tile-content {
        width: 70%;
    }
}

@media (max-width: 1439px) and (min-width: 1315px) {
    .fiveHundred.matcard_content .mat-grid-tile .mat-grid-tile-content {
        width: 80%;
    }
}

@media (max-width: 1314px) {
    .fiveHundred.matcard_content .mat-grid-tile .mat-grid-tile-content {
        width: 85%;
    }
}

@media (max-width: 1600px) {
    .paymentLeft {
        width: 30%;
    }

    .paymentRight {
        width: 70%;
    }
}

@media (max-width: 1500px) {
    #dashboardData mat-paginator.payment-paginator .mat-mdc-paginator-range-actions button {
        width: 30px;
    }
}

/*MobileResponsive*/
@media (max-width: 967px) {
    mat-horizontal-stepper .mat-horizontal-stepper-header-container {
        margin: 0px auto;
        width: 100%;
    }

    .mat-drawer-content {
        display: contents;
    }

    #headLeft {
        color: $sidebar-selected-item;
        margin-top: 60px;
        border-bottom: 1px solid #dedddd;
        padding-bottom: 10px;
        width: 100%;
        padding: 0px 15px 5px 15px;
    }

    .mat-drawer-container {
        background-color: #ffffff;
        color: #000000;
    }

    .mat-drawer-container .page-content {
        color: black;
        font-size: 14px;
        margin-top: 50px;
    }

    .content-header .row {
        display: block;
        position: relative;
        bottom: 0px;
    }

    .table-fields mat-form-field {
        width: 100%;
    }
    .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
        display: none;
    }
    .mat-mdc-tab-list {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
    }
    .mat-mdc-tab-labels {
        display: flex;
        flex-wrap: nowrap;
    }
    #opportunityCom .mat-mdc-tab.mdc-tab--active {
        flex: 0 0 auto;
    }
}

@media (max-width: 600px) {
    #headLeft {
        margin-top: 34px;
    }

    //mobile screen search button width & tab padding
    .search input {
        width: 150px !important;
    }
    .reports-tab .mat-mdc-tab-body-content {
        padding: 2px 0px !important;
    }
    .reports-tab .mat-mdc-tab-body-content {
        padding: 0px !important;
    }

    .user-login-popup {
        min-width: 95vw !important;
        max-width: 95vw !important;
    }

    .cart-wrapper {
        overflow: hidden;
        min-width: 100vw !important;
        max-width: 100vw !important;
    }
}

.user-theme-button {
    background-color: $sidebar-selected-item !important;
    border-radius: 50px;
    color: #fff !important;
}

.errorClassStyle .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    margin: 0;
}

.table-stricky {
    max-height: 282px;
    overflow: auto;
    border-top: 1px solid $color-light-gray;
    padding-bottom: 21px;
}

.table-stricky table {
    border-top: none !important;
}

.mat-mdc-form-field {
    font-size: inherit;
    font-weight: 400;
    line-height: 1.125;
    font-family: 'Source Sans Pro', sans-serif;
}

.mdc-text-field__input .mdc-floating-label {
    font-size: 12px;
}

.mat-mdc-text-field-wrapper .mat-mdc-floating-label {
    top: 22px !important;
    font-size: 12px !important;
}

.mdc-floating-label--float-above.mat-mdc-floating-label {
    top: 28px !important;
}

.popup .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 15px !important;
}

.mat-mdc-checkbox label {
    font-weight: 600;
}

.mat-mdc-form-field-infix {
    max-height: 300px;
    overflow: auto;
    min-height: 40px !important;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;

    &:has(textarea) {
        max-height: unset;
    }
}

.mat-chip__container {
    .mat-mdc-form-field-infix {
        max-height: unset !important;
        display: block;
    }
    .mat-mdc-form-field {
        padding-top: 7px;
    }
    .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex .mat-mdc-floating-label {
            top: 26px !important;
        }
    }
}

.mat-mdc-form-field-subscript-wrapper {
    display: none;
}

.mat-mdc-icon-button svg {
    width: 18px !important;
    height: 18px !important;
}

.audit-header .mat-mdc-tab-labels {
    flex-direction: row;
}

.audit-header mat-tab-group.mat-mdc-tab-group {
    flex-direction: column !important;
}

.audit-header .mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab:last-child {
    height: 36px !important;
}

.audit-header mat-tab-header.mat-mdc-tab-header .mat-mdc-tab .mdc-tab__content {
    font-weight: bold;
}

.audit-header .mat-mdc-tab {
    border: none !important;
}

.daily_reports,
.modifierpanel {
    .panel-heading,
    th.mat-mdc-header-cell.report-header-row {
        border-bottom: 2px solid $sidebar-selected-item;
    }

    .head-text {
        font-weight: 600;
        color: $sidebar-selected-item;
    }
}

#scrollToTopButton {
    background-color: $sidebar-selected-item;
    position: fixed;
    bottom: 30px;
    z-index: 1000;
    right: 35px;
    color: white;
    opacity: 0.7;
    padding: 0;

    .mat-icon {
        font-size: 34px;
        height: 36px;
        width: 36px;
    }
}

.auto_form .custom-select.ng-select-multiple.ng-select .ng-select-container {
    height: auto;
    max-width: 260px;
}

.auto-depart .custom-select .ng-has-value .ng-placeholder {
    top: 12px;
}

.auto-depart .custom-select.ng-select-single.ng-select .ng-select-container {
    min-height: 44px;
    height: 100%;
}

.auto-depart .custom-select .ng-value-label {
    white-space: normal;
}

.currencyPosition .mat-mdc-form-field-infix {
    align-items: center;
}

.custom-field-check .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    margin: 0;
}

/* for empty tables */
.mat-mdc-table.table_empty td,
.mat-mdc-table.t#snav #matnavList .mat-mdc-list-item.selected > .mdc-list-item__content a {
    background: #ffffff;
    color: $sidebar-selected-item;
}

#snav #matnavList .mat-mdc-list-item.selected > .mdc-list-item__content a mat-icon {
    color: inherit;
}

.mat-mdc-table.table_empty td a {
    max-width: unset !important;
    width: unset !important;
}

/*All Docs Module CSS*/
.api-docs {
    padding: 10px 10px 10px 10px !important;
    font-weight: 500;

    // .content-headername {
    //   margin: 0px 10px 5px;
    //   border-bottom: 1px solid #cccccc94;
    //   font-size: 22px;
    //   //color:$sidebar-selected-item;
    // }

    .content-header {
        height: 55px;
        background-color: #ffffff;

        .mat-icon {
            width: 25px;
        }
    }

    h4 {
        margin-left: 15px;
    }

    ul {
        margin-bottom: 15px;
    }

    p {
        margin: 0 15px 15px;
    }

    .images {
        display: block;
        margin: 0 auto;
        width: 80%;
    }
}

.api-docs-back-btn {
    .material-icons {
        font-size: 12px;
    }

    .content-header {
        padding: 5px 15px;
        background-color: #ffffff;
    }
}

.dxmain .side-content {
    a.activeclass.activeClick,
    a.activeclass.activeScroll {
        color: $sidebar-selected-item;
    }
}

.header .headerName {
    color: $sidebar-selected-item;
    font-size: 21px;
}

#snav .mat-mdc-list-item .tabSection .material-icons,
#snav .mat-mdc-list-item .tabSection span,
#snav .mat-mdc-list-item .childSection .material-icons,
#snav .mat-mdc-list-item .childSection span {
    color: $sidebar-selected-item;
}

/*All Docs Module CSS */

.txtcolumn {
    max-width: 200px;
    word-break: break-all;
}

.txtWrap {
    word-break: break-word;
}

.no-data {
    text-align: center;
    margin-top: 10px;
    color: black;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.addErrorValidation .mat-form-field-outline * {
    border: 0px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.addErrorValidation .mat-form-field-outline {
    border: 2px solid $color-red;
    border-radius: 5px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.addErrorValidation .mat-form-field-label {
    color: $color-red;
    background: #ffffff;
}

.addErrorValidation .mat-mdc-form-field-infix {
    display: flex;
    align-items: flex-start;
}

.addErrorValidation .ng-placeholder {
    color: $color-red !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-should-float.mat-focused.addErrorValidation label {
    color: $color-red !important;
    width: auto !important;
}

/** floating timer **/
.task_timers.timer_collapsed {
    .mat-expansion-panel-header {
        background: $sidebar-selected-item !important;

        &:hover {
            background: $sidebar-selected-item !important;
        }
    }
}

.floating-timer .single_timer .mat-expansion-panel-header {
    background: $sidebar-selected-item !important;
}

.timer-content {
    width: 100%;
}

/** floating timer **/

.loaderForPayNow .load {
    top: -5%;
    text-align: center;
    position: absolute;

    .user_msg_outer {
        right: 0;
        left: 0;
        position: absolute;
        bottom: 5%;
    }

    .user_msg {
        color: blue;
        font-size: 16px;
        font-weight: 400;
        background: #fff;
        padding: 0 5px;
    }
}

//START Status design for list view & details view

.statusbtn .caret {
    position: relative;
    top: 8px;
    float: right;
}

.status_string {
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    margin-top: 2px;
    text-overflow: ellipsis;
    word-break: break-word;
    vertical-align: middle;
    width: 85%;
}

.noEditPermission {
    .tag-btn {
        text-align: center;
        line-height: 15px;
        height: 20px;
        min-height: 20px;
    }

    .status_string {
        max-width: 100px;
        width: 100%;
        margin-top: 0;
    }
}

#loader-task,
#loader-oppo,
#ticketCom,
#loader-pro,
.poList,
.allleave-comp,
.myleave-comp {
    .inactiveClientStatus {
        text-align: center;
    }
}

// listing page and detial page status
.leads-details-page .ng-select.tag-btn,
.detailsPage .detail_section .tag-btn,
.bugDetails .tag-btn,
#taskDetail .tag-btn {
    width: 120px;
    display: inline-block;
}

.inv-main-content .tag-btn {
    min-width: 26%;
    max-width: 40%;
    float: right;
    height: 20px;
    min-height: 20px;
    line-height: 18px;
    text-align: center;
}

.clientView .inv-main-content .tag-btn {
    width: auto;
    max-width: unset;
}

.ng-select.tag-btn .ng-value-container {
    padding-left: 5px;
}

.ng-select.tag-btn {
    width: 80%;
    padding: 0px !important;

    .ng-dropdown-panel {
        width: 150px;
    }

    .ng-placeholder {
        max-width: 80px;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
    }

    .ng-select-container {
        height: 22px;
        min-height: 22px;
        border: 0;
    }
}

.ng-select.tag-btn.ng-select-filtered .ng-placeholder,
.ng-select.tag-btn .ng-has-value .ng-placeholder {
    display: none;
}

.tag-btn.progressButton {
    .ng-arrow {
        border-color: #0000ff transparent transparent !important;
    }

    .ng-placeholder,
    .ng-value-label {
        color: #0000ff !important;
    }

    .ng-select-container {
        &:hover {
            background-color: #d8d8ff !important;
        }
    }
}

.tag-btn.progressButton.ng-select-opened {
    .ng-arrow {
        border-color: transparent transparent #0000ff !important;
    }
}

.tag-btn.btn_deferrd {
    .ng-arrow {
        border-color: grey transparent transparent !important;
    }

    .ng-placeholder,
    .ng-value-label {
        color: grey !important;
    }

    .ng-select-container {
        &:hover {
            background-color: #e8e6dc !important;
        }
    }
}

.tag-btn.btn_deferrd.ng-select-opened {
    .ng-arrow {
        border-color: transparent transparent gray !important;
    }
}

.tag-btn.voiletButton {
    .ng-arrow {
        border-color: #9b00e2 transparent transparent !important;
    }

    .ng-placeholder,
    .ng-value-label {
        color: #9b00e2 !important;
    }

    .ng-select-container {
        &:hover {
            background-color: #9a00e241 !important;
        }
    }
}

.tag-btn.voiletButton.ng-select-opened {
    .ng-arrow {
        border-color: transparent transparent #9b00e2 !important;
    }
}

.tag-btn.btnNew {
    color: blueviolet;
    border: 1px solid blueviolet;

    .ng-arrow {
        border-color: blueviolet transparent transparent !important;
    }

    .ng-placeholder,
    .ng-value-label {
        color: blueviolet !important;
    }

    .ng-select-container {
        &:hover {
            background-color: #8a2be247 !important;
        }
    }
}

.tag-btn.btnNew.ng-select-opened {
    .ng-arrow {
        border-color: transparent transparent blueviolet !important;
    }
}

.tag-btn.btn_warning_to {
    .ng-arrow {
        border-color: orange transparent transparent !important;
    }

    .ng-placeholder,
    .ng-value-label {
        color: orange !important;
    }

    .ng-select-container {
        &:hover {
            background-color: #ffe0b3 !important;
        }
    }
}

.tag-btn.btn_warning_to.ng-select-opened {
    .ng-arrow {
        border-color: transparent transparent orange !important;
    }
}

.tag-btn.warningButton {
    .ng-arrow {
        border-color: #f35d05 transparent transparent !important;
    }

    .ng-placeholder,
    .ng-value-label {
        color: #f35d05 !important;
    }

    .ng-select-container {
        &:hover {
            background-color: #f35c0542 !important;
        }
    }
}

.tag-btn.warningButton.ng-select-opened {
    .ng-arrow {
        border-color: transparent transparent #f35d05 !important;
    }
}

.tag-btn.warningButton {
    .ng-arrow {
        border-color: #f35d05 transparent transparent !important;
    }

    .ng-placeholder,
    .ng-value-label {
        color: #f35d05 !important;
    }

    .ng-select-container {
        &:hover {
            background-color: #f35c0542 !important;
        }
    }
}

.tag-btn.warningButton.ng-select-opened {
    .ng-arrow {
        border-color: transparent transparent #f35d05 !important;
    }
}

.tag-btn.dangerButton {
    .ng-arrow {
        border-color: #c81010 transparent transparent !important;
    }

    .ng-placeholder,
    .ng-value-label {
        color: #c81010 !important;
    }

    .ng-select-container {
        &:hover {
            background-color: rgba(200, 16, 16, 0.253) !important;
        }
    }
}

.tag-btn.dangerButton.ng-select-opened {
    .ng-arrow {
        border-color: transparent transparent #c81010 !important;
    }
}

.tag-btn.successButton {
    .ng-arrow {
        border-color: #00963c transparent transparent !important;
    }

    .ng-placeholder,
    .ng-value-label {
        color: #00963c !important;
    }

    .ng-select-container {
        &:hover {
            background-color: rgba(0, 150, 60, 0.253) !important;
        }
    }
}

.tag-btn.successButton.ng-select-opened {
    .ng-arrow {
        border-color: transparent transparent #00963c !important;
    }
}

@media (min-width: 1600px) {
    .status_string {
        max-width: 95px;
    }

    .tag-btn,
    .ng-select.tag-btn {
        width: 62%;
    }

    .inv-main-content .tag-btn {
        width: 20%;
    }

    .noEditPermission {
        .status_string {
            max-width: 120px;
        }
    }
}

@media (min-width: 1800px) {
    .inv-main-content .tag-btn {
        width: 18%;
    }
}

@media (max-width: 1600px) {
    .leads-details-page .ng-select.tag-btn,
    .detailsPage .detail_section .tag-btn,
    .bugDetails .tag-btn,
    .display_prop .mat-column-projectName .tag-btn,
    .opp_name .tag-btn,
    .display_prop .mat-column-projectname .tag-btn,
    #taskDetail .tag-btn {
        .status_string {
            max-width: 90px;
        }
    }
}

@media (max-width: 1300px) {
    .inv-main-content .tag-btn {
        width: 30%;
    }
}

@media (max-width: 1200px) {
    .tag-btn,
    .ng-select.tag-btn {
        width: 85%;
    }

    .table.mat-mdc-cell tr,
    th,
    td {
        font-size: 13px !important;
    }

    .actionIcon {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .material-icons {
            font-size: 12px;
        }

        .btn.disabled {
            border: none;
            opacity: 0.5;
        }
    }
}

//END Status design for list view & details view

// calendar list day view
.fc-list-table td {
    padding: 8px 10px !important;
}

.hand-cursor {
    cursor: pointer;
}

// Add more item icon
.additem:after {
    content: '+';
    cursor: pointer;
    height: 13px;
    width: 13px;
    display: block;
    position: absolute;
    color: white;
    border: 2px solid white;
    border-radius: 14px;
    box-shadow: 0 0 3px #444;
    box-sizing: content-box;
    text-align: center;
    line-height: 13px;
    background-color: $sidebar-selected-item;
    margin-top: 2px;
}

.add-more {
    margin-left: 25px;
}

//invoice, estimate, PO description cell
.item-desc-table {
    word-break: break-all;
}

/* View Page Item & Categories */

.detail-wrap .view-title {
    padding: 15px 20px;
}

.detail-wrap .view-title .heading {
    display: inline-block;
    margin: 0;
    font-weight: 600;
    color: #555;
    font-size: 18px;
    line-height: 26px;
}

.detail-wrap .view-title .btn-wrapper {
    display: inline-block;
    float: right;
}

.detail-wrap .view-title .btn-wrapper > button {
    min-width: auto;
    border-radius: 0;
    margin-left: 10px;
    padding: 0 8px;
    line-height: 28px;
}

.bg-white {
    background-color: #fff;
}

.detail-wrap .detail-content .title {
    background-color: #f2f4f5;
    color: #212223;
    margin: 0;
    padding: 10px 20px;
    font-size: 16px;
}

.detail-wrap .small-header {
    font-size: 12px;
    color: #838383;
    font-weight: bold;
    margin-bottom: 5px;
}

.detail-wrap .small-content {
    font-weight: bold;
    color: #434344;
    font-size: 15px;
}

.detail-wrap .mx-0 {
    margin: 0 0px;
}

/* View Page Item & Categories */

// Group tax changes css starts here
.group-tax-table-title {
    font-weight: 600;
}

.group-tax-disabled-class {
    pointer-events: none;
}

.group-tax-name {
    padding: 10px;
    padding-left: 0px;
}

::ng-deep .ng-select.group-tax-disabled-class .ng-arrow-wrapper .ng-arrow {
    border-style: none;
}

.payment-detail-table {
    padding-bottom: 20px;
}

.recurredPadding {
    padding-left: 15px;
    padding-bottom: 20px;
}

// Group tax changes css ends here

// Common css for bill,invoice,po,estimate form starts here
.client-invisible {
    color: $color-red;
}

.divider3 {
    border: solid 1px #efe9e9;
    width: calc(100% + 50px);
    margin-left: -28px;
}

.disabledSubmit {
    opacity: 0.5;
    pointer-events: none;
    background-color: lightgray;
}

.enableSubmit {
    pointer-events: all;
}

.div-width {
    width: 100%;
}

.parentd-div {
    width: 100%;
    min-height: 150px;
}

.clientInfo {
    min-height: 150px;
}

.clientName {
    padding-top: 10px;
    width: 73%;
}

.plusdiv {
    margin-top: 30px;
    padding: 10px;
    margin-bottom: 10px;
}

.divider2 {
    border: solid 1px #efe9e9;
    margin-top: 20px;
    width: calc(100% + 50px);
    margin-left: -25px;
}

.general {
    padding: 10px 0px 0px 10px;
    display: inline-block;
    width: 100%;
}

#inv-detail-content {
    padding: 10px;
    background-color: white;
    min-height: 700px;
}

.urldiv .mat-mdc-form-field-infix {
    font-family: 'Open Sans', sans-serif;
}

.light-font {
    font-weight: lighter;
}

.inv-sub-main-content {
    padding: 3px;
    background-color: #f3f4f6;
    min-height: 650px;
    margin-top: 10px;
}

// Common css for bill,invoice,po,estimate form ends here

.showToClient {
    background-color: #ff902b !important;
}

// common css for view pages start here
.view-print {
    margin-right: 0px;
}

.view-pdf {
    padding-left: 2px;
}

// common css for view pages end here

//Accounting css start here
.date_full_box {
    .repcardApplyBtn {
        font-size: 15px;
        margin-right: 10px;
        border-radius: 20px;
    }

    .card-block {
        margin-top: 2px;
    }
}

//Accounting css end here
/** new date time picker **/
.startEndDt.input-group {
    display: block;

    .no-gutters {
        margin-right: 0;
        margin-left: 0;
        flex-wrap: wrap;
        display: flex;
    }

    .right-btn button.btn {
        position: absolute;
        right: 0;
        top: 10px;
    }

    .dl-abdtp-view-label {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .no-gutters > .col,
    .no-gutters > [class*='col-'] {
        padding-right: 0;
        padding-left: 0;
    }

    .align-items-center {
        align-items: center !important;
    }

    .col.align-items-center.no-gutters.dl-abdtp-col-label {
        text-align: center;
        padding: 5px 0px;
        display: block;
    }

    .dropdown-menu {
        width: 70%;
    }

    .no-gutters button {
        background: transparent;
        margin-bottom: 5px;
    }
}

/** new date time picker **/

/* Menu Builder Page */

.menu-wrap .mat-step {
    margin-bottom: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.cat-group .mat-radio-label-content {
    font-weight: 600 !important;
    text-transform: capitalize;
}

.cat-group mat-radio-button {
    border: 1px solid $sidebar-selected-item;
    padding: 4px 6px;
    border-radius: 29px;
    margin-right: 8px;
    margin-bottom: 8px;
    color: $sidebar-selected-item;
    transition: 0.4s all;
}

.cat-group mat-radio-button.mat-radio-checked,
.cat-group mat-radio-button:hover {
    background-color: $sidebar-selected-item;
    color: #fff;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.cat-group .mat-radio-label {
    margin-bottom: 0;
}

.menu-wrap mat-step-header {
    padding: 16px 24px;
    background-color: #eff0f2;
    pointer-events: none;
}

.menu-wrap .mat-vertical-content {
    padding: 24px 24px 24px 24px;
}

.menu-wrap .mat-stepper-vertical-line::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left-width: 1px;
    border-left-style: dashed;
    border-left-color: rgba(0, 0, 0, 0.2);
}

.menu-wrap .mat-step-header .mat-step-icon.mat-step-icon-selected {
    background-color: $sidebar-selected-item;
    color: rgb(255, 255, 255);
}

.menu-wrap .mat-step-header .mat-step-icon-state-edit,
.menu-wrap .mat-step-header .mat-step-icon {
    background-color: #c7cdda;
    color: rgba(0, 0, 0, 1);
}

.menu-wrap .mat-step-header .mat-step-label.mat-step-label-active {
    padding-top: 2px;
}

.menu-wrap .mat-step-header .mat-step-label-selected {
    color: $sidebar-selected-item !important;
}

.menu-wrap .item-box {
    background-color: $sidebar-selected-item;
}

.item-box mat-checkbox .mat-mdc-checkbox-inner-container,
.menu-wrap .promocode-li .mdc-checkbox,
.menu-wrap .modifier-li .mdc-checkbox,
.cat-group .mdc-radio__background {
    display: none;
}

.item-box mat-checkbox .mat-mdc-checkbox-label {
    line-height: 1;
}

.item-box mat-checkbox .mat-mdc-checkbox-layout {
    white-space: normal;
    margin-bottom: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.menu-wrap .promocode-li .mat-mdc-checkbox label {
    line-height: 1;
    text-transform: capitalize;
}

.menu-wrap .select2-container.select2-container--open {
    position: relative !important;
    padding-bottom: 0 !important;
    bottom: 0 !important;
}

.menu-wrap .select2-container .select2-dropdown {
    position: relative;
    box-shadow: none !important;
}

.menu-wrap .select2-container--default .select2-results > .select2-results__options {
    max-height: 110px !important;
}

.menu-builder-filter .select2-container--default .select2-results > .select2-results__options {
    max-height: 85px !important;
}

.mat-mdc-unelevated-button.custom-theme-flat-button {
    background-color: $sidebar-selected-item;
    color: #fff;
    border-radius: 30px;
}

.mat-mdc-unelevated-button.custom-theme-flat-button:hover {
    box-shadow:
        0 40px 12px rgba(70, 156, 74, 0.25) inset,
        0 -40px 12px rgba(70, 156, 74, 0.25) inset;
}

.mat-mdc-outlined-button.custom-theme-stroked-button {
    border-radius: 30px;
    border-color: #99a0a6;
    color: #82898f;
}

.reports-tab .mat-mdc-tab-body-content {
    padding: 15px;
}

/*** to display dropdown at top of daily reports  **/
.reports-tab .mat-mdc-tab-body.mat-mdc-tab-body-active,
.reports-det .mat-mdc-tab-body-wrapper {
    overflow: visible;
}

.category-box.active {
    border: 1px solid $sidebar-selected-item;
}

.category-box.active .category-name {
    background: $sidebar-selected-item;
    color: #fff;
}

.promocode-btn {
    float: right;
    border: 1px solid $sidebar-selected-item;
    background: #fff;
    margin: 0 10px;
    border-radius: 15px;
    background: $sidebar-selected-item;
    color: #fff;
    padding: 5px 15px;
}

.promocode-btn:hover {
    background: $sidebar-selected-item;
}

.form-inner .content-box .search {
    margin-top: 0;
}

.search button.mat-mdc-outlined-button {
    vertical-align: top;
}

.filter-btns-rounded button.active,
.custom-period-diolog .show-btn {
    background-color: $sidebar-selected-item !important;
    color: #fff;
}

.custom-file-upload .img-upload > .file-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    color: $sidebar-selected-item;
    margin-bottom: 0;
    cursor: pointer;
}

.toggle-button.active {
    background-color: $sidebar-selected-item;
    border-color: $sidebar-selected-item;
    color: #fff;
}

.toggle-button.active {
    background-color: $sidebar-selected-item;
    border-color: $sidebar-selected-item;
    color: #fff;
}

// End Menu Builder

/* online order */
$CSview: #9d6bd9;

.main-online-order {
    background: #ffffff;
    min-height: 530px;
    margin-top: 70px;
    padding-bottom: 40px;

    .mat-divider {
        border-color: $sidebar-selected-item;
    }

    .mat-mdc-tab-header {
        height: auto;
        margin-bottom: 0px;
        padding: 0;
        color: $sidebar-selected-item;
    }

    .mat-mdc-unelevated-button.mat-primary,
    .mat-mdc-raised-button.mat-primary,
    .mat-mdc-fab.mat-primary,
    .mat-mdc-mini-fab.mat-primary,
    .mat-flat-button.mat-primary a {
        background: $sidebar-selected-item;
        color: #fff;
        font-weight: 600;
        border-radius: 30px;
    }

    .tab-menu-img {
        button.mat-mdc-mini-fab.mat-primary {
            background: rgba(0, 0, 0, 0.12);
        }

        .mat-mdc-unelevated-button.mat-primary[disabled],
        .mat-flat-button.mat-accent[disabled],
        .mat-flat-button.mat-warn[disabled],
        .mat-flat-button[disabled][disabled],
        .mat-mdc-raised-button.mat-primary[disabled],
        .mat-raised-button.mat-accent[disabled],
        .mat-raised-button.mat-warn[disabled],
        .mat-raised-button[disabled][disabled],
        .mat-mdc-fab.mat-primary[disabled],
        .mat-fab.mat-accent[disabled],
        .mat-fab.mat-warn[disabled],
        .mat-fab[disabled][disabled],
        .mat-mdc-mini-fab.mat-primary[disabled],
        .mat-mini-fab.mat-accent[disabled],
        .mat-mini-fab.mat-warn[disabled],
        .mat-mini-fab[disabled][disabled] {
            background: $sidebar-selected-item;
        }
    }
    .mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab.mdc-tab--active {
        color: $sidebar-selected-item;
    }

    .mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab.cdk-focused.cdk-mouse-focused,
    .mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab:hover,
    .mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab:focus {
        background: $sidebar-selected-item;
        color: #fff;
    }

    .mat-mdc-button.mat-primary,
    .mat-mdc-icon-button.mat-primary,
    .mat-mdc-outlined-button.mat-primary {
        color: $sidebar-selected-item;
    }

    .content-header-name a {
        color: #fff;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
        color: $sidebar-selected-item;
    }

    .content-header {
        height: 50px;
        background: $sidebar-selected-item;
        box-shadow: none;
        padding: 5px 20px;
        box-sizing: border-box;
        margin-bottom: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .content-header-name {
        font-size: 20px;
        margin-bottom: 0px;
        margin-top: 0px;
        font-weight: 600;
        cursor: pointer;
        min-width: fit-content;
    }

    &.product-page {
        .mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab.cdk-focused.cdk-mouse-focused,
        .mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab:hover,
        .mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab:focus {
            background: none;
            color: #fff;
        }
        .mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab.mdc-tab--active {
            background: none;

            .item-cat-div .cat_name_box {
                background: $sidebar-selected-item;

                p {
                    color: #fff;
                }
            }
        }

        .item-cat-div:hover {
            .cat_name_box {
                background-color: $sidebar-selected-item;

                p {
                    color: #fff;
                }
            }
        }

        .menuitem_details_wrap {
            ._item_name,
            ._item_price {
                color: $sidebar-selected-item;
            }
        }
    }

    &.item-detail-page {
        .item-detail-section {
            .text-sharp .text1,
            .titleClass .currency {
                color: $sidebar-selected-item;
            }
        }

        .reviewImage,
        .btn_wrapper button {
            background-color: $sidebar-selected-item;
        }

        .review_field {
            textarea {
                border: 1px solid $sidebar-selected-item;

                &::placeholder {
                    color: $sidebar-selected-item;
                }
            }
        }

        .carousel-indicators > button[disabled] {
            background-color: $sidebar-selected-item !important;
        }

        .no_data_wrapper {
            .oops-text {
                color: $sidebar-selected-item;
            }
        }
    }
}

.online-order-login {
    margin-bottom: 0px !important;
}

.empty-cart-page {
    height: calc(100vh - 75px);
}

.mat-mdc-button.auto-alert-btn.olo {
    background-color: $color-green;
    border-radius: 25px;
    color: #fff !important;
    font-weight: 600;
}

@media (max-width: 991px) {
    .main-online-order {
        margin-top: 50px;
    }

    .cart-amt-details {
        position: unset;
        right: unset;
        margin-right: 0;
    }
}

@media (max-width: 600px) {
    .main-online-order {
        margin-bottom: 0px;

        .content-header-name {
            font-size: 15px;
        }

        .content-header {
            padding: 5px 10px;
        }
    }
}

// Filter CSS Start here
.boxSides {
    width: 250px;
}

.mainBoxSize {
    height: 250px;
    width: 250px;
}

.pointer {
    cursor: pointer;
}

.boxSize {
    margin-top: 20px;
    margin-left: 30px;

    .ng-dropdown-panel {
        max-height: 150px;
    }

    .ng-dropdown-panel-items {
        max-height: 147px !important;
    }

    .ng-dropdown-panel {
        min-width: 100%;
    }
}
.append-body-class.ng-dropdown-panel.ng-select-bottom {
    overflow: visible;
    .ng-dropdown-panel {
        max-height: 150px;
    }
    .ng-dropdown-panel-items {
        max-height: 150px;
        border: 1px solid;
        opacity: 1;
        border-color: #d1cdcd;
        background: $sideBar-light-color;
        color: $sideBar-dark-color;
    }
    .ng-dropdown-panel {
        min-width: 100%;
    }
    .ng-option.ng-option-selected,
    .ng-option.ng-option-selected.ng-option-marked {
        background-color: white !important;
    }
}
.append-body-class.ng-dropdown-panel.ng-select-bottom {
    overflow: visible;

    .ng-dropdown-panel {
        max-height: 148px;
    }

    .ng-dropdown-panel-items {
        max-height: 148px;
        border: 1px solid;
        opacity: 1;
        border-color: #d1cdcd;
    }

    .ng-dropdown-panel {
        min-width: 100%;
    }

    .ng-option.ng-option-selected,
    .ng-option.ng-option-selected.ng-option-marked {
        background-color: white !important;
    }
}

.hide-checkbox {
    .mat-pseudo-checkbox {
        display: none;
        opacity: 1 !important;
    }
}

// Filter CSS End here

.red-light .mat-mdc-slide-toggle .mdc-switch--checked .mdc-switch__track::after {
    background-color: rgba(244, 67, 54, 0.54) !important;
}

.red-light .mat-mdc-slide-toggle .mdc-switch--checked .mdc-switch__handle::after {
    background-color: #f44336 !important;
}

.green-light .mat-mdc-slide-toggle .mdc-switch--checked .mdc-switch__track::after {
    background-color: rgba(76, 175, 80, 0.51) !important;
}

.green-light .mat-mdc-slide-toggle .mdc-switch--checked .mdc-switch__handle::after {
    background-color: #4caf50 !important;
}

.purple-light .mat-mdc-slide-toggle .mdc-switch--checked .mdc-switch__track::after {
    background-color: rgba(159, 88, 176, 0.54) !important;
}

.purple-light .mat-mdc-slide-toggle .mdc-switch--checked .mdc-switch__handle::after {
    background-color: #9f58b0 !important;
}

.mat-mdc-slide-toggle .mdc-switch svg {
    display: none !important;
}

/** new date time picker **/
.startEndDt.input-group {
    display: block;

    .no-gutters {
        margin-right: 0;
        margin-left: 0;
        flex-wrap: wrap;
        display: flex;
    }

    .right-btn button.btn {
        position: absolute;
        right: 0;
        top: 10px;
    }

    .dl-abdtp-view-label {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .no-gutters > .col,
    .no-gutters > [class*='col-'] {
        padding-right: 0;
        padding-left: 0;
    }

    .align-items-center {
        align-items: center !important;
    }

    .col.align-items-center.no-gutters.dl-abdtp-col-label {
        text-align: center;
        padding: 5px 0px;
        display: block;
    }

    .dropdown-menu {
        width: 100%;
    }

    .no-gutters button {
        background: transparent;
        margin-bottom: 5px;
    }
}

/** new date time picker **/

// dark theme fixes start here
.mdc-checkbox {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-frame {
        border-color: rgba(0, 0, 0, 0.54) !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-checkmark-path {
        stroke: #fafafa !important;
    }
}

.mdc-radio__background {
    .mdc-radio__outer-circle {
        border-color: rgba(0, 0, 0, 0.54);
    }
}

.mat-form-field-appearance-outline {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-outline {
        color: rgba(0, 0, 0, 0.12);
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label,
    .mat-mdc-form-field-hint {
        color: rgba(0, 0, 0, 0.6);
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-fill .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.42);
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
.mat-slide-toggle-label {
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-slide-toggle-bar {
        background-color: rgba(0, 0, 0, 0.38);
    }
}

// dark theme fixes end here

// Item table qty validation css start here
.itemdata-qty.errorClass {
    border: unset !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.itemdata-qty.errorClass .mat-form-field-outline {
    color: $color-red !important;
    opacity: 1;
}

// Item table qty validation end here

// Task , project, ticket description start here

.task-des {
    min-height: 150px;
    border: 1px solid gainsboro;
    color: #000 !important;
    border-radius: 5px;
}

//changing a tab color while redirecting
.open-new {
    color: unset !important;
    text-decoration: none !important;
}

// Task , project, ticket description end here

.filter-dd .ng-value-container {
    overflow: auto;
    height: 100%;
}

.pdf-left {
    margin-left: 14px;
}

/* Added memo width*/
.mat-column-Memo,
.BankAndCash .mat-column-Description,
.transaction_rpt .mat-column-Note {
    max-width: 230px;
    cursor: pointer;

    .memo_name {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        margin-top: 2px;
        text-overflow: ellipsis;

        &:hover {
            word-break: break-word;
            white-space: pre-wrap;
        }
    }
}

._location_label {
    color: $sidebar-selected-item;
    background-color: rgba($sidebar-selected-item, 0.15);

    i {
        color: $sidebar-selected-item !important;
    }
}

.header-profile-wrapper {
    .cart-btn {
        background-color: $sidebar-selected-item;
    }

    .btn_login,
    .btn_login i,
    i.fa-search,
    i.fa-times {
        color: $sidebar-selected-item !important;
    }

    .btn_login {
        background-color: rgba($sidebar-selected-item, 0.15);
    }
}

.active_theme_color {
    color: $sidebar-selected-item !important;
}

.active_theme_hr {
    border-top: 2px solid $sidebar_selected_item !important;
}

.active_theme_btn {
    background-color: $sidebar-selected-item !important;
}

.active_theme_bordered_btn {
    border-color: $sidebar-selected-item !important;
    color: $sidebar-selected-item !important;
}

.carousel .carousel-indicators > button[disabled] {
    background-color: $sidebar-selected-item !important;
}

ngu-carousel .myPoint li.active {
    background-color: $sidebar-selected-item !important;
}

::ng-deep .special_menu_modal {
    .modal_wrap .modal_footer .skip-add-btn {
        background-color: $sidebar-selected-item;
    }

    .modal_wrap .specialmenu_details_wrap .specialmenu_name_wrap .special-menu-name,
    .modal_wrap .specialmenu_details_wrap .specialmenu_name_wrap .special-menu-price {
        color: $sidebar-selected-item;
    }
}

.menuBuilderlist_table .ui-treetable .ui-sortable-column.ui-state-highlight {
    background-color: $sidebar-selected-item;
}

// common classes to apply theme color

.theme-text-color {
    color: $sidebar-selected-item !important;
}

.theme-bg-color-light {
    background-color: rgba($sidebar-selected-item, 0.25) !important;
}

.theme-bg-color-low-light {
    background-color: rgba($sidebar-selected-item, 0.07) !important;
}
.theme-bg-color {
    background-color: $sidebar-selected-item !important;
}

.theme-color-on-hover {
    &:hover {
        color: $sidebar-selected-item !important;
    }
}

.theme-color-rating .mat-mdc-icon-button.mat-accent {
    color: $sidebar-selected-item !important;
}

// knowledgebase module

app-kb-overview .kboverview {
    .kb_cat_wrapper .kb_cat_inner:hover .cat_title {
        color: $sidebar-selected-item;
    }

    .kb_cat_wrapper .kb_cat_inner .btn_all {
        background-color: rgba($sidebar-selected-item, 0.25);
        color: $sidebar-selected-item;

        &:hover {
            background-color: rgba($sidebar-selected-item, 1);
            color: #fff;
        }
    }
}

.work-align {
    border-bottom: 2px solid $sidebar-selected-item;
}

.detail-view-mat-title {
    color: $sidebar-selected-item;
    font-weight: bold;
    padding: 10px 20px;
}

.mat-mdc-tab-header .mat-mdc-tab-list .mat-mdc-tab.mdc-tab--active .mdc-tab__content a,
.primary-details {
    color: $sidebar-selected-item;
}

.subscribe-pay-divider {
    margin: 0 !important;
    border-color: $sidebar-selected-item;
}

.mat-mdc-tooltip.basic-tooltip,
.basic-tooltip .mdc-tooltip__surface-animation {
    font-size: 14px;
}

.mat-mdc-tooltip.basic-cust-tooltip {
    font-size: 14px;
    white-space: pre-line;
    min-width: 750px;
}

.floormap-header {
    border-bottom: 2px solid $sidebar-selected-item;
}

.wizard-logout {
    .mat-icon {
        color: $sidebar-selected-item;
    }
}

.text-info {
    margin-bottom: 5px;
}

.remove-app-expansion {
    mat-expansion-panel-header,
    mat-expansion-panel-header:hover {
        background-color: rgba($sidebar-selected-item, 0.75) !important;
    }
}

.disableDiv {
    pointer-events: none;
}

.mat-expension-scroll {
    .mat-expansion-panel-content {
        overflow: scroll;
        max-height: 200px;

        &:hover {
            &::-webkit-scrollbar {
                width: 10px;
                height: 10px;
                display: block !important;

                &:hover {
                    visibility: visible;
                    border-radius: 9px;
                    // background-color: rgba(0, 0, 0, 0.3);
                    background-color: #fff;
                }
            }

            &::-webkit-scrollbar-thumb {
                background: 0 0;
                background-color: rgba(0, 0, 0, 0.2);
                border: 2px solid transparent;
                border-radius: 10px;
                background-clip: padding-box;
            }
        }
    }
}

.mat-accordion-scroll {
    overflow: scroll;
    max-height: 300px;

    &:hover {
        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            display: block !important;

            &:hover {
                visibility: visible;
                border-radius: 9px;
                background-color: #fff;
            }
        }

        &::-webkit-scrollbar-thumb {
            background: 0 0;
            background-color: rgba(0, 0, 0, 0.2);
            border: 2px solid transparent;
            border-radius: 10px;
            background-clip: padding-box;
        }
    }
}

.device-info-card {
    overflow: scroll;

    &:hover {
        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            display: block !important;

            &:hover {
                visibility: visible;
                border-radius: 9px;
                background-color: #fff;
            }
        }

        &::-webkit-scrollbar-thumb {
            background: 0 0;
            background-color: rgba(0, 0, 0, 0.2);
            border: 2px solid transparent;
            border-radius: 10px;
            background-clip: padding-box;
        }
    }
}

.inv-check {
    color: green;
    font-weight: 700;
}

.inv-clear {
    color: $color-red;
    font-weight: 700;
}

// Payroll report
.theme-text-color {
    color: $sidebar-selected-item !important;
}

// ticket replies button color
.theme_button_color {
    background-color: $sidebar-selected-item;
    color: $white !important;
}

.theme_button_color {
    background-color: $sidebar-selected-item !important;

    &[disabled] {
        opacity: 0.75;
    }
}

.panel-body figure.image img {
    max-width: 100%;
}

.panel-body .tinyClass img {
    max-width: 100%;
    height: auto;
}

.side-content code {
    color: #0e0e0e;
    background-color: transparent !important;
}

.active-meeting-link {
    font-weight: 600 !important;
    color: $sidebar-selected-item !important;

    code {
        background-color: rgba($sidebar-selected-item, 0.25) !important;
        color: $sidebar-selected-item !important;
    }

    &:hover {
        text-decoration: none !important;
    }
}

.panel-body figure.image img,
.tinyClass figure.image img {
    max-width: 100%;
}

.tinyClass img,
.message-text img {
    max-width: 100%;
    height: auto;
}

.tinyClass {
    ul {
        list-style: disc;
    }
    ol {
        list-style: decimal;
    }
    iframe {
        max-width: 100%;
    }
    img {
        display: inline-block;
    }
}

.article-body, .tinyClass, .message-text {
    ul, ol {
        padding-left: 2rem;
    }
    a {
        color: #0d6efd;
    }
}

/* setting border styles to table */
table.tinymce__table[border]:not([border='0']):not([style*='border-color']),
.tinyClass table[border]:not([border='0']):not([style*='border-color']) {
    td,
    th {
        padding: 5px 6px;
        border-color: rgb(204, 204, 204);
    }
}

table.tinymce__table[border]:not([border='0']):not([style*='border-style']),
.tinyClass table[border]:not([border='0']):not([style*='border-style']) {
    td,
    th {
        border-style: solid;
    }
}

table.tinymce__table[border]:not([border='0']):not([style*='border-width']),
.tinyClass table[border]:not([border='0']):not([style*='border-width']) {
    td,
    th {
        border-width: 1px;
    }
}

/* custom radio button css */
.custom-radio-active {
    border: 1px solid $sidebar-selected-item;
    border-radius: 8px;
    padding: 7px;
    text-align: center;
    color: $sidebar-selected-item;
    font-weight: bold;
    background-color: rgb(255, 255, 255);
    position: relative;
    cursor: pointer;
    font-size: 13px;
}

.custom-radio-active .active-icon {
    border: 1px solid $sidebar-selected-item;
    border-radius: 50%;
    padding: 4px;
    position: absolute;
    top: -7px;
    right: -6px;
    color: white;
    background: $sidebar-selected-item;
    font-size: 10px;
}

// min-height for fist time app loading
.full-layout-wrapper .page-content {
    min-height: 80vh !important;
}

// common file dropzone
.dropzone {
    height: 120px;
    display: flex;
    width: 100%;
    background-color: #eee;
    border: dotted 1px #aaa;
    align-items: center;
    justify-content: center;

    input[type='file'] {
        display: none;
    }

    .centered {
        font-size: 14px;
        text-align: center;
        color: #767676;

        h4 {
            margin-top: 0;
            margin-bottom: 26px;
            font-weight: 600;
        }

        label {
            font-weight: 500;
        }
    }

    .selectTextLink {
        background-color: $sidebar-selected-item;
        color: #fff;
        padding: 10px;
        border-radius: 5px;
        box-shadow:
            0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);

        .fa-upload {
            margin-right: 6px;
        }
    }
}

// uploaded files list

.selected_files_wrap {
    margin-top: 5px;
    padding: 10px;

    .col-md-6 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .file_box {
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.12);
        background-color: #f5f5f5;
        margin-bottom: 5px;
        padding: 6px 10px;
        border-radius: 5px;
        line-height: 1;
        justify-content: space-between;

        .icon_delete {
            color: #ff3300;
            font-size: 16px;
            margin-left: 5px;
            cursor: pointer;
        }

        .file_icon {
            color: $sidebar-selected-item;
            font-size: 16px;
            margin-right: 5px;
            vertical-align: middle;
        }

        .file_name {
            font-weight: 600;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 12px;
        }
    }
}

// suheader responsiveness for tab
@media only screen and (max-width: 967px) and (min-width: 768px) {
    #headLeft {
        background-color: white;
        text-align: right;
    }

    .cart-wrapper {
        overflow: hidden;
        min-width: 60vw !important;

        .mat-mdc-dialog-container {
            padding: 15px !important;
        }
    }
}

@media only screen and (max-width: 1439px) and (min-width: 1919px) {
    .cart-wrapper {
        overflow: hidden;
        min-width: 36vw !important;
        max-width: 36vw !important;

        .mat-mdc-dialog-container {
            padding: 15px !important;
        }
    }
}

@media only screen and (min-width: 1920px) {
    .cart-wrapper {
        overflow: hidden;
        min-width: 36vw !important;
        max-width: 36vw !important;
    }
}

//header serchbox overlapping laptop screen
@media only screen and (min-width: 1440px) {
    .search-bar .header-search {
        width: 70% !important;
    }
}

.pannel-title-master-dept {
    background-color: $sidebar-selected-item !important;
    height: 28px !important;
    color: white;

    h5 {
        color: white !important;
        font-weight: 600;
    }
}

.panel-title-sub-dept {
    background-color: rgba($sidebar-selected-item, 0.07) !important;
    height: 30px !important;
    color: $sidebar-selected-item;

    h5 {
        color: $sidebar-selected-item !important;
    }
}

.main-dept,
.sub-dept {
    .item-box {
        border-color: white !important;
        background: rgba($sidebar-selected-item, 0.1) !important;
    }

    .left-line {
        border: 2px solid $sidebar-selected-item !important;
        background: rgba($sidebar-selected-item, 0.5) !important;
    }

    .add-schedule-icon:hover {
        background: $sidebar-selected-item;
        transition: 1s;
        color: #f7f7f7;
    }

    .dashboard-title {
        background-color: rgba($sidebar-selected-item, 0.1) !important;
    }
}

// survey logicflow dropdown
.logic-flow-select .select2-results__options .select2-results__option {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ticket-email-inputs {
    max-height: 76px !important;
    overflow-y: auto !important;
}

//  Gift card selected theme
.gift-card-page {
    .content-header {
        background: $sidebar-selected-item;
    }
}

.gift-cart-full {
    .gift-cart-left {
        .gift-cart-text {
            h5 {
                color: $sidebar-selected-item;
            }

            hr {
                background: $sidebar-selected-item;
            }
        }

        .gift-btn-select .purchase-gift .btn-active {
            background: $sidebar-selected-item;
        }
    }

    .gift-cart-right {
        .right-container .purchase-gift-text {
            h5 {
                color: $sidebar-selected-item;
            }

            hr {
                background: $sidebar-selected-item;
            }
        }

        .custom-amount button {
            background: $sidebar-selected-item;
        }

        .pay-now-btn button {
            background: $sidebar-selected-item;
        }

        .reload-btn a {
            color: $sidebar-selected-item;
        }

        .right-container .cart-no button {
            background: $sidebar-selected-item;
        }
    }
}

.stage-button {
    color: #00b050 !important;
    border: 1px solid #00b050 !important;
}

.tag-btn.stage-button {
    width: 150px !important;

    .ng-placeholder {
        color: #00b050 !important;
        max-width: 128px !important;
    }
}

.make-default {
    color: green;
}

.not-default {
    color: #ff0000;
}

// for date range filter
.date-filter {
    padding: 10px;
}

// for mozilla firefox browser
@-moz-document url-prefix() {
    #opportunityCom #opp-cont-dev .support-filter button.custom-item-btn {
        bottom: 3px;
    }
}

//logo css
.brand-logo-height {
    line-height: 1.4 !important;
    text-align: center !important;
}

// kanban stage css
.kanban-stage-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}

.btn-arrow-right {
    color: #fff;
    font-size: 14px;

    &:hover {
        color: #fff;
    }
}

.btn.kanaban-btn.active.focus,
.btn.kanaban-btn.active:focus,
.btn.kanaban-btn.focus,
.btn.kanaban-btn:active.focus,
.btn.kanaban-btn:active:focus,
.btn.kanaban-btn:focus {
    outline: none;
}

// display scrollbar
.display-scrollbar {
    &::-webkit-scrollbar {
        display: initial;
        visibility: visible;
        border-radius: 10px;
        height: 4px;
        width: 12px;
        background-color: $color-light-gray;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: 0 0;
        background-color: rgba(0, 0, 0, 0.2);
        border: 2px solid transparent;
        border-radius: 10px;
        background-clip: padding-box;
    }
}

// mailCC style on leave
.leave-tag-input .ng2-tag-input.ng2-tag-input--focused {
    border: 2px solid $sidebar-selected-item !important;
}

.leave-tag-input .ng2-tag-input.ng2-tag-input--invalid {
    border: 2px solid $color-red !important;
}

//Social media icons
.social-media-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
    margin: 20px 0;

    .fa-facebook {
        color: #4267b2;
    }

    .fa-yelp {
        color: $color-red;
    }

    i {
        cursor: pointer;
        font-size: 36px;
    }
}

.twitter-icon {
    height: 36px;
    width: 36px;
    cursor: pointer;
}

.customer-view {
    [data-letters]:before {
        background: $sidebar-selected-item;
    }
}

.survey-log-out {
    [data-letters]:before {
        background: $sidebar-selected-item;
    }
}

.create-acc {
    border: 1px solid $sidebar-selected-item !important;
    color: $sidebar-selected-item !important;
}

// leads settings
.lead-settings-form {
    .btn-div button {
        margin: 2px 5px;
    }
}

// auto save comments draft label
.draft-label {
    padding: 5px 12px;
    font-size: 12px;
    font-weight: 700;
    font-style: italic;
    line-height: 1;
    color: white;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: rgba($sidebar-selected-item, 1);
    border-radius: 20px;
}

@-moz-document url-prefix() {
    .list-filter-menu-inner ng-select.custom-select {
        &.three-fields-dd,
        &.two-fields-dd {
            .ng-select-container .ng-value-container {
                scrollbar-width: none;
            }
        }

        &.ng-select-single .ng-select-container {
            scrollbar-width: none;
        }
    }

    // hide scrollbar
    .page-wrapper,
    .mat-drawer-inner-container,
    .wrapper {
        scrollbar-width: none;
    }
}

// chatbot UI
.answer-thumb {
    background-color: rgba($sidebar-selected-item, 0.2) !important;
    color: $sidebar-selected-item !important;
}

.question-box-styling {
    table {
        border-collapse: collapse;
        width: 100%;

        th {
            background-color: #d5d5d5;
        }

        th,
        td {
            padding: 10px;
            border-style: solid;
            border-color: $color-light-gray;
            border-width: 1px;
        }
    }
}

.mat-mdc-tooltip.multiline-tooltip {
    font-size: 12px;
    white-space: pre-line;
}

//google icon color for font awesome icon
.google-icon-color {
    background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150%
        no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
}

// custom tabs UI with routing
.mat-tab-with-routing {
    .mat-mdc-tab-links {
        border-bottom: 2px solid rgba($sidebar-selected-item, 1) !important;

        .mat-mdc-tab-link {
            font-weight: bold;
            color: #000;
            opacity: 1;
            min-width: auto;
            padding: 0 15px;
            max-width: fit-content;
            text-decoration: none !important;
            &.mdc-tab--active {
                background: #fff;
                color: rgba($sidebar-selected-item, 1);
            }

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
}

// common filter UI for kanban & list view
.common-list-filter {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px 10px;

    .left-col,
    .right-col {
        display: flex;
        gap: 10px;
    }

    .common-filter-btn {
        border: 1px solid #d3d3d3;
        padding: 0;
        min-width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
        background: none;
        .mat-icon {
            width: auto;
            height: auto;
        }
    }

    .search {
        float: none;
    }
}

.list-filter-menu-inner {
    padding: 15px 15px;
    .filter-action-btns {
        margin-bottom: 15px;
        position: relative;
    }
    .filter-close-btn {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        display: inline-block;
        border: none;
        background-color: transparent;
    }
    .form-group {
        margin-bottom: 10px;
    }
    form {
        .mat-mdc-form-field {
            padding: 0 0;
            .mat-mdc-text-field-wrapper {
                margin: 0 0;
                padding-bottom: 0;
            }
        }
        .mat-mdc-form-field.disabled .mat-mdc-text-field-wrapper {
            background-color: #f9f9f9;
            pointer-events: none;
        }
    }
    .d-block {
        display: block;
    }
    ng-select.custom-select {
        width: auto;
        height: auto;
        &.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
            padding-bottom: 0px;
        }
        &.multi-select {
            .ng-select-container {
                height: auto;
                overflow: hidden;
                align-self: flex-start;
                .ng-value-container {
                    align-self: flex-start;
                    overflow: auto;
                    padding-top: 6px;
                    padding-left: 10px;
                    height: 100%;
                    overflow-y: auto;
                    overflow-x: hidden;
                }
                .ng-arrow-wrapper,
                .ng-clear-wrapper {
                    align-self: flex-start;
                    margin-top: 10px;
                }
            }
        }
        &.ng-select-disabled .ng-select-container {
            pointer-events: none;
            .ng-clear-wrapper,
            .ng-value .ng-value-icon.left {
                display: none;
            }
        }
        &.dd-1x .ng-select-container {
            height: 100px;
        }
        &.dd-2x .ng-select-container {
            height: 120px;
        }
        &.dd-3x .ng-select-container {
            height: 153px;
        }
    }
    .filter-form {
        .row > .col-md-6 > .form-group.form-default:last-child {
            margin-bottom: 0;
        }
        @media screen and (max-width: 768px) {
            .row > .col-md-6:not(:last-child) .form-group.form-default:last-child {
                margin-bottom: 10px;
            }
        }
    }
}

// ng select dropdown padding fix
.ng-dropdown-panel.custom-select.multi-select {
    .ng-dropdown-panel-items .ng-option {
        padding: 6px 10px;
        input[type='checkbox'] {
            margin: 0 0 0;
        }
    }
}

::ng-deep .list-filter-menu {
    .cdk-overlay-pane {
        max-width: 768px;
        width: 100%;
    }
}

.list-filter-menu + * .cdk-overlay-pane {
    max-width: 768px;
    width: 100%;
}
.list-filter-menu + * .mat-mdc-menu-panel {
    max-width: 768px;
    width: 100%;
    .mat-mdc-menu-content {
        padding: 0 0 !important;
    }
}

// common text ellipsis
.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.theme-mat-icon svg g path {
    fill: $sidebar-selected-item;
    stroke: $sidebar-selected-item;
}

.theme-mat-outline-icon svg g path {
    fill: $sidebar-selected-item;
    stroke: none !important;
}

.rsv-info__container .theme-mat-outline-icon {
    background-color: rgba($sidebar-selected-item, 0.07);
}

.item-custom-dialog {
    width: 100%;
    align-items: center;
}

.custom-panel-reservation-confirm {
    max-width: 90vw !important;
    mat-dialog-container,
    mat-dialog-container .mdc-dialog__surface {
        border-radius: 15px;
    }

    .mat-mdc-dialog-title {
        color: #9d6bd9 !important;

        &:before {
            display: none;
        }
    }

    .mat-mdc-dialog-actions {
        padding: 0px;
        min-height: 0px;
        margin-bottom: 0px;
    }
}

.custom-dialog-container {
    mat-dialog-container {
        padding: 24px 0;
        border-radius: 4px;
    }
}

.client-page,
#invCom,
.rmaDetails {
    .urldiv {
        max-width: 530px;
    }

    .url-cls {
        padding: 5px;
    }
}

// tinyMCE editor fullscreen
.tox.tox-tinymce.tox-fullscreen {
    padding: 2vw;
    background-color: rgba($color: #000000, $alpha: 0.25);
}

// remove number field arrows
input[type='number'].remove-arrows {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
}

.flex-full-width {
    width: 100%;
    margin: 5px 0;
}

.vertical-tab__container {
    .mat-mdc-tab-labels {
        flex-direction: row !important;
    }

    .mat-mdc-tab-group {
        display: flex !important;
        flex-direction: column !important;
    }

    .mat-mdc-tab-header {
        height: 50px !important;
        border-bottom: 2px solid $sidebar-selected-item !important;
        padding: 8px 15px !important;
        width: 100% !important;
    }

    .mat-mdc-tab-body-wrapper {
        flex: 1 1 auto;
    }

    .mat-mdc-tab {
        border: none !important;
        font-size: 14px;
        text-align: left;
        color: $sidebar-selected-item;
        height: 40px !important;
        padding-right: 15px;
    }
}
.cdk-overlay-pane {
    &:has(.modal-100) {
        width: 120px !important;
    }

    &:has(.modal-200) {
        width: 220px !important;
        right: 20px !important;
    }

    &:has(.modal-300) {
        width: 320px !important;

        .mat-pseudo-checkbox {
            display: none !important;
        }
    }

    .mdc-list-item__primary-text {
        opacity: 1 !important;
    }
}

th.mat-mdc-header-cell {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 12px;
}

#dashboardData th {
    font-size: 12px;
}

a.btn {
    color: rgb(0, 122, 217);
    font-size: 14px;
}

.panel-footer {
    padding: 10px 15px;
    background-color: #fafafa;
    border-top: 1px solid #eeeeee;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.statistics-data .ng-input {
    left: 12px !important;
}

.panel-heading {
    padding: 7px 15px;
    color: #333;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 300;
}

.panel-body {
    padding: 7px;
}

.panel-default {
    border-color: #ddd !important;
}

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

    .mat-icon {
        width: 18px !important;
        height: 18px !important;
    }
}

.userImg span {
    padding: 0;
}

.taskAttachments,
.audit-header {
    .mat-mdc-tab-header {
        width: 100%;
    }
}

.audit-header,
.comments-container {
    ul {
        padding-left: 40px;
    }
}

.taskboard-task {
    margin-bottom: 10px;
    border-radius: 0.25rem;
    height: auto !important;
    padding: 10px;
    overflow: unset;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.toast-message {
    font-size: 16px;
}

.modal-200.mat-mdc-option,
.visibility-column-options.mat-mdc-option {
    height: 36px;
    min-height: unset;

    span {
        color: #000000 !important;
        font-family: Roboto, 'Helvetica Neue', sans-serif !important;
        font-weight: 500 !important;
        font-size: 12px !important;
    }

    .mat-pseudo-checkbox-checked {
        background: #3f51b5 !important;
    }
}

.mat-mdc-select-panel:has(.modal-200, .visibility-column-options) {
    padding: 0;
}

@mixin no-ripple {
    .mat-mdc-button-persistent-ripple,
    .mat-mdc-button-ripple {
        border-radius: 0;
        --mat-icon-button-state-layer-color: transparent !important;
        --mat-icon-button-ripple-color: transparent !important;
        --mat-text-button-state-layer-color: transparent !important;
        --mat-text-button-ripple-color: transparent !important;
    }
}

.content-header,
.telative {
    @include no-ripple;
}

.fa-list-ul {
    color: #4b0082;
}

.bill-clone-screen {
    .mat-dialog-container {
        max-width: none;
        padding: 0px;
    }
}

.payroll-custom-outline-styles {
    ::ng-deep .mat-form-field-appearance-outline.mat-form-field:hover .mat-form-field-outline-thick {
        color: #f2f3f4;
    }

    ::ng-deep .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
        color: transparent;
    }
}

.footer-copyright {
    font-size: 14px;
}

ng-select.noError {
    border-color: rgba(0, 0, 0, 0.38);
}

// remove border from action buttons
.action-btn {
    .btn.disabled {
        border: none !important;
    }
}

.dialog-back-btn {
    height: auto !important;
    font-size: 20px !important;
}
.toast-container {
    position: fixed !important;
}

.btn.btn-action {
    border: none !important;
}

.slideInDown {
    border-radius: 4px;

    .mat-mdc-dialog-container {
        border-radius: 4px;
    }
}

.p-checkbox .p-checkbox-box {
    border: 1px solid #a6a6a6;

    &:hover {
        border-color: #212121;
    }

    &.p-highlight {
        border-color: #fff !important;
    }
}

.p-checkbox .p-checkbox-box {
    border: 1px solid #a6a6a6;

    &:hover {
        border-color: #212121;
    }

    &.p-highlight {
        border-color: #fff !important;
    }
}
