/* common classes */

.proposal-template-wrapper .txt-purple {
    color: rgba(129, 30, 204, 1);
}

.proposal-template-wrapper .txt-blue {
    color: rgba(0, 56, 142, 1);
}

.m-b-0 {
    margin-bottom: 0;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-t-0 {
    margin-top: 0;
}

.m-l-0 {
    margin-left: 0;
}

.m-r-0 {
    margin-right: 0;
}

.p-b-0 {
    padding-bottom: 0 !important;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-l-0 {
    padding-left: 0;
}

.p-r-0 {
    padding-right: 0;
}

.d-flex {
    display: flex;
}

.proposal-template-wrapper .tr-blue-bg {
    background-color: rgba(0, 56, 142, 1);
    color: rgba(255, 255, 255, 1);
}

/* titles circles */

.proposal-template-wrapper .circle-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.proposal-template-wrapper .circle-wrapper .circle {
    border-radius: 50%;
    width: 22vw;
    height: 22vw;
    background-size: cover;
    background-repeat: no-repeat;
}

.proposal-template-wrapper .circle-wrapper .circle > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.proposal-template-wrapper .circle-title {
    position: absolute;
    width: 54%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    container-type: inline-size;
}

.proposal-template-wrapper .circle-wrapper .circle-title h2 {
    font-size: 16cqw;
    color: white !important;
    z-index: 2;
    padding: calc(4px + 0.25vw);
    text-align: center;
    font-weight: bold;
    margin: 0;
}

.proposal-template-wrapper .content-wrapper .content-fluid {
    max-width: 1000px;
    padding: 0 15px;
    margin: 0 auto;
}

/* this need to remove later */
.proposal-template-wrapper {
    max-width: 1200px;
    margin: 0 auto;
}

/* this need to remove later */

.proposal-template-wrapper .banner-image {
    margin-bottom: 15px;
}

.proposal-template-wrapper .banner-image img {
    width: 100%; /* this need to remove after proper image */
}

.proposal-template-wrapper section.template-page {
    padding: 40px 0;
}

/* Pricing Section */

.proposal-template-wrapper .pricing .table-circle-with-title td {
    width: 40%;
    padding: 0 10px;
}

.proposal-template-wrapper .pricing .table-circle-with-title .circle-wrapper.purple-circle {
    display: inline-block;
}

.proposal-template-wrapper .pricing .table-circle-with-title td:last-child {
    width: 60%;
}

.proposal-template-wrapper section.template-page.pricing .item-table td,
section.template-page.pricing .item-table th {
    border: 1px solid #d5d5d5;
    padding: 10px 5px;
}

.proposal-template-wrapper section.template-page.pricing .table-quote > tbody > tr > td {
    padding-bottom: 10px;
}

.proposal-template-wrapper section.template-page.pricing .table-quote td:not(:last-child) {
    vertical-align: top;
}

.proposal-template-wrapper .pricing .table-box:not(.full-width-table) tr td {
    text-align: center;
    width: 20%;
    margin-bottom: 10px;
}

.proposal-template-wrapper .pricing .table-box tr td {
    border: 1px solid rgba(0, 56, 142, 1);
}

.proposal-template-wrapper pricing .table-quote .box-with-title .box-details {
    padding: 8px 5px;
}

.proposal-template-wrapper .pricing .box-with-title .box-details.with-border {
    border-left: 1px solid rgba(0, 56, 142, 1);
    border-right: 1px solid rgba(0, 56, 142, 1);
    border-bottom: 1px solid rgba(0, 56, 142, 1);
    padding: 8px 5px;
}

.proposal-template-wrapper .pricing .table-location-detail > tbody > tr > td:first-child {
    width: 80%;
    padding-left: 0;
    padding-right: 15px;
    vertical-align: top;
}

.proposal-template-wrapper .pricing .table-location-detail > tbody > tr > td:last-child {
    width: 20%;
    vertical-align: top;
}

.proposal-template-wrapper section.template-page.pricing .item-table td {
    vertical-align: top;
}

.proposal-template-wrapper section.template-page.pricing .item-table td:nth-last-child(-n + 2) {
    text-align: right;
}

.proposal-template-wrapper section.template-page.pricing .item-table th:nth-last-child(-n + 2) {
    text-align: center;
}

.proposal-template-wrapper section.template-page.pricing .item-table tfoot td:first-child {
    text-align: left;
    padding: 5px;
    vertical-align: middle;
}

.proposal-template-wrapper .box-with-title .box-title {
    background: rgba(0, 56, 142, 1);
    color: white;
    text-align: center;
    font-size: 13px;
    padding: 5px;
}

.proposal-template-wrapper .box-with-title .box-title.main-title {
    padding: 12px;
    font-size: 16px;
}

.proposal-template-wrapper .box-with-title .box-details.with-border {
    padding: 8px 5px;
}

.proposal-template-wrapper section.template-page.pricing .line-with-text .hr-line {
    padding: 12px 5px;
    min-height: 120px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    margin-bottom: 2px;
    display: flex;
    align-items: flex-end;
}

.proposal-template-wrapper section.template-page.pricing .line-with-text .hr-line img {
    max-height: 90px;
}

.proposal-template-wrapper .pricing ul {
    padding-inline-start: 0;
}

.proposal-template-wrapper .pricing ul > li {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
}

.proposal-template-wrapper .pricing ul > li p {
    margin-bottom: 0;
}

.proposal-template-wrapper .pricing .content-txt h3 {
    font-weight: bold;
}

.proposal-template-wrapper .pricing .table-signature {
    width: calc(80% - 15px) !important;
}

.proposal-template-wrapper .pricing .table-signature td:first-child {
    padding-right: 10px;
    word-break: break-all;
    width: 50%;
    vertical-align: bottom;
}

.proposal-template-wrapper .pricing .table-signature td:last-child {
    padding-left: 10px;
    width: 50%;
    vertical-align: bottom;
}

.proposal-template-wrapper .pricing .table-circle-with-title .tick-icon {
    max-width: 28px;
}

.proposal-template-wrapper .pricing .table-circle-with-title .tick-icon > img {
    width: auto !important;
    height: auto !important;
}

.proposal-template-wrapper [data-template-tooltip] {
    position: relative;
}

.proposal-template-wrapper [data-template-tooltip]&:before {
    content: attr(data-template-tooltip);
    position: absolute;
    top: -15px;
    background: #ffffcc;
    color: #000;
}

.proposal-template-wrapper .text-center {
    text-align: center;
}

.mce-non-preview [data-template-tooltip] {
    border: dashed 1px rgb(144, 164, 174);
    padding: 1.75rem 0.25rem 0.25rem 0.25rem;
    position: relative;
    background-color: rgba(207, 216, 220, 0.2);
}

.mce-non-preview [data-template-tooltip]:before {
    content: attr(data-template-tooltip);
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(207, 216, 220);
    font-size: 11px;
    padding: 0.25rem 0.45rem;
    color: red;
    font-weight: 600;
}

.proposal-template-wrapper section.template-page.pricing table {
    width: 100%;
}

.proposal-template-wrapper section.template-page.pricing table.table-quote .table-box td {
    padding: 0;
}

/* why choose us section */

.proposal-template-wrapper .why-choose-us-li {
    padding-inline-start: 0;
}

.proposal-template-wrapper .why-choose-us-li > li {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
}

.proposal-template-wrapper .why-choose-us-li > li h3 {
    color: rgba(129, 30, 204, 1);
    font-weight: 600;
}

.proposal-template-wrapper .why-choose-us-li .icon-wrap {
    flex: 0 0 54px;
    text-align: center;
    max-width: 54px;
}

.proposal-template-wrapper .why-choose-us-li .icon-wrap > img {
    max-width: 100%;
    max-height: 54px;
}

/* our advantage section */

.proposal-template-wrapper section.template-page.our-advantage .circle-with-title {
    vertical-align: baseline;
}

.proposal-template-wrapper .our-advantage ul {
    padding-inline-start: 0;
}

.proposal-template-wrapper .our-advantage ul > li {
    gap: 15px;
    align-items: flex-start;
    margin-bottom: 10px;
}

.proposal-template-wrapper .our-advantage .circle-bullet {
    flex: 0 0 3vw;
    max-width: 3vw;
}

.proposal-template-wrapper .our-advantage ul > li h3 {
    font-weight: bold;
}

.proposal-template-wrapper .our-advantage .circle-wrapper.blue-circle {
    position: relative;
    top: -6vw;
}

.proposal-template-wrapper .our-advantage .circle-wrapper.blue-circle .circle {
    width: 12vw;
    height: 12vw;
}

/* client section */

.proposal-template-wrapper .our-clients .client-img-wrap {
    display: inline-block;
    max-width: 260px;
}

.proposal-template-wrapper .our-clients .client-img-wrap > img {
    max-height: 160px;
    width: auto !important;
    height: auto !important;
}

.proposal-template-wrapper .our-clients .content-fluid table td {
    text-align: center;
    padding: 10px;
    width: 33.3333%;
    max-width: 33.3333%;
}

.proposal-template-wrapper .our-clients .circle-with-title .circle-wrapper .circle {
    width: 100%;
    height: 100%;
}

.proposal-template-wrapper .our-clients .circle-wrapper .circle-title h2 {
    font-size: 18cqw;
}

.proposal-template-wrapper .our-clients .circle-with-title {
    position: relative;
}

.proposal-template-wrapper .our-clients .circle-with-title .circle-wrapper.blue-circle {
    position: relative;
    margin-top: -90px;
    display: inline-block;
}

/* equipment section */

.proposal-template-wrapper .your-equipment .equipement-info {
    display: flex;
    gap: 10px;
}

.proposal-template-wrapper .your-equipment .equipment-img-box {
    margin-bottom: 10px;
    max-width: 260px;
    height: 160px;
}

.proposal-template-wrapper .your-equipment .equipment-img-box > img {
    max-height: 160px;
}

.proposal-template-wrapper .your-equipment .equipement-info .checkbox-icon i {
    font-size: 2.5rem;
}

.proposal-template-wrapper .your-equipment .content-fluid table td {
    padding: 10px;
    width: 33.3333%;
    max-width: 33.3333%;
}

.proposal-template-wrapper .your-equipment .equipment-details h4 {
    font-weight: 600;
}

.proposal-template-wrapper .your-equipment .circle-wrapper .circle-title h2 {
    font-size: 15cqw;
}

.proposal-template-wrapper .your-equipment .circle-wrapper.purple-circle {
    display: inline-block;
}

.proposal-template-wrapper .your-equipment .circle-with-title .circle-wrapper .circle {
    width: 100%;
    height: 100%;
}

/* home section */

.proposal-template-wrapper section.template-page.home-section .circle-wrapper,
section.template-page.home-section .logo-wrapper {
    display: inline-block;
    max-width: 310px;
}

.proposal-template-wrapper section.template-page.home-section .c1x .circle {
    width: 5vw;
    height: 5vw;
}

.proposal-template-wrapper section.template-page.home-section .c2x .circle {
    width: 12vw;
    height: 12vw;
}

.proposal-template-wrapper section.template-page.home-section .c3x .circle {
    width: 16vw;
    height: 16vw;
}

.proposal-template-wrapper section.template-page.home-section .c4x .circle {
    width: 24vw;
    height: 24vw;
}

.proposal-template-wrapper section.template-page.home-section .c3x {
    bottom: 0;
    left: 18vw;
    position: absolute;
}

.proposal-template-wrapper section.home-section .table-circle-with-title td {
    vertical-align: top;
    position: relative;
    width: 50%;
    display: table-cell;
}

.proposal-template-wrapper section.home-section .table-circle-with-desciption td {
    width: 50%;
    padding-left: 5vw;
    padding-bottom: 2vw;
    vertical-align: bottom;
}

.proposal-template-wrapper section.home-section .circles-merge {
    width: 28vw;
    position: relative;
    padding-bottom: 6vw;
    margin-top: -10vw;
}

.proposal-template-wrapper section.template-page.home-section .c1x {
    top: 4vw;
    left: 2vw;
}

.proposal-template-wrapper section.home-section .table-circle-with-desciption .content-txt {
    max-width: 322px;
}

.proposal-template-wrapper section.home-section .table-circle-with-desciption .content-txt h4 {
    text-align: right;
    font-style: italic;
    line-height: 1.2;
}

.proposal-template-wrapper .table-circle-with-title td {
    padding: 15px;
}

@media screen and (max-width: 767px) {
    .proposal-template-wrapper .pricing .table-signature {
        width: 100% !important;
    }
    .proposal-template-wrapper .table-circle-with-title td {
        display: block;
        padding: 5px 15px;
    }
    .proposal-template-wrapper .circle-with-title .circle-wrapper .circle,
    .proposal-template-wrapper .our-clients .circle-with-title .circle-wrapper .circle {
        width: 60px;
        height: 60px;
    }
    .proposal-template-wrapper .circle-wrapper .circle-title {
        position: relative;
        width: auto;
        left: auto;
        top: auto;
        transform: none;
        container-type: unset;
    }
    .proposal-template-wrapper .circle-wrapper .circle-title h2 {
        color: rgba(129, 30, 204, 1) !important;
        padding-left: 6px;
        font-size: 22px !important;
    }
    /* our-advantage */
    .proposal-template-wrapper .our-advantage .circle-wrapper.blue-circle {
        display: none;
    }
    .proposal-template-wrapper .our-advantage ul > li h3 {
        font-size: 18px;
    }
    .proposal-template-wrapper .our-advantage ul {
        padding-inline-start: 20px;
    }
    .proposal-template-wrapper .our-advantage .circle-bullet {
        flex: 0 0 32px;
        max-width: 32px;
    }

    /* your-equipment */
    .proposal-template-wrapper .your-equipment .content-fluid table td {
        width: 100%;
        max-width: 100%;
    }
    .proposal-template-wrapper .your-equipment .circle-wrapper.purple-circle {
        display: flex;
    }
    .proposal-template-wrapper .your-equipment .circle-with-title .circle-wrapper .circle {
        width: 60px;
        height: 60px;
    }

    /* why-choose-us */
    .proposal-template-wrapper .why-choose-us .table-circle-with-title tr {
        display: flex;
        flex-direction: column-reverse;
    }
    .proposal-template-wrapper .why-choose-us-li > li h3 {
        font-size: 18px;
    }
    .proposal-template-wrapper .why-choose-us-li .icon-wrap {
        flex: 0 0 42px;
        max-width: 42px;
    }
    .proposal-template-wrapper .why-choose-us-li {
        padding-inline-start: 10px;
    }

    /* pricing */
    .proposal-template-wrapper .pricing .table-location-detail > tbody > tr > td:first-child {
        margin-bottom: 15px;
    }
    .proposal-template-wrapper .pricing .table-circle-with-title td,
    .pricing .table-circle-with-title td:last-child {
        width: 100%;
    }
    .proposal-template-wrapper .pricing .table-circle-with-title .circle-wrapper.purple-circle {
        display: flex;
    }
    .proposal-template-wrapper .pricing .table-circle-with-title .circle-with-title {
        padding-bottom: 10px;
    }

    .proposal-template-wrapper .pricing .table-circle-with-title td .content-txt {
        padding-left: 4em;
    }

    .proposal-template-wrapper .pricing .table-location-detail > tbody > tr > td:first-child,
    .proposal-template-wrapper .pricing .table-location-detail > tbody > tr > td:last-child {
        width: 100%;
        display: block;
        padding-right: 0;
    }

    .proposal-template-wrapper .pricing .table-location-detail td {
        vertical-align: top;
    }

    .proposal-template-wrapper .pricing .table-box.full-width-table td:last-child {
        margin-bottom: 0;
    }

    .proposal-template-wrapper .pricing .table-box.full-width-table td {
        width: 100%;
        display: block;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .proposal-template-wrapper .pricing .table-box:not(.full-width-table) tr {
        display: flex;
        flex-wrap: wrap;
    }

    .proposal-template-wrapper .pricing .table-box:not(.full-width-table) tr td:nth-child(-n + 3) {
        width: 33.333333%;
    }

    .proposal-template-wrapper .pricing .table-box:not(.full-width-table) tr td:nth-last-of-type(-n + 2) {
        width: 50%;
        margin-bottom: 0;
    }

    .proposal-template-wrapper .pricing .box-with-title .box-title {
        font-size: 12px;
    }

    /* home section */
    .proposal-template-wrapper section.home-section .table-circle-with-desciption td {
        display: block;
        width: 100%;
    }

    .proposal-template-wrapper section.home-section .table-circle-with-desciption .content-txt {
        max-width: 100%;
    }

    .proposal-template-wrapper section.home-section .table-circle-with-desciption .content-txt h4 {
        text-align: left;
    }

    /* client */
    .proposal-template-wrapper .our-clients .content-fluid table td.circle-with-title {
        vertical-align: top;
    }

    .proposal-template-wrapper .our-clients .circle-with-title .circle-wrapper.blue-circle {
        position: relative;
        margin-top: 0;
        display: flex;
    }

    .proposal-template-wrapper .our-clients .content-fluid table td {
        width: 100%;
        display: block;
        max-width: 100%;
    }
}

@media print {
    * {
        color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }
    /* section {page-break-after: always;} */
    .proposal-template-wrapper .box-with-title .box-title {
        background: rgba(0, 56, 142, 1) !important;
        color: white !important;
    }
    .proposal-template-wrapper section.template-page.pricing .item-table tfoot > tr:not(:nth-child(2)) td {
        background: rgba(0, 56, 142, 1) !important;
        color: white !important;
    }
    .proposal-template-wrapper section.template-page.pricing .item-table td,
    .proposal-template-wrapper section.template-page.pricing .item-table th {
        border: 1px solid #d5d5d5 !important;
    }
    .proposal-template-wrapper section.template-page.pricing .item-table thead {
        background: rgba(200, 200, 200, 1) !important;
    }
    .proposal-template-wrapper .txt-purple,
    .why-choose-us-li > li h3 {
        color: rgba(129, 30, 204, 1) !important;
    }
    .proposal-template-wrapper .txt-blue {
        color: rgba(0, 56, 142, 1) !important;
    }
}
